import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from 

"angularx-social-login";;
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../helpers/mustMatchValidtor';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  user: any;
  resetpassword: FormGroup;
  submitted = false;
  loggedIn: boolean = false;
  token = "";
  showForm = 0;

  constructor(
    private route: ActivatedRoute, private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private socialAuthService: SocialAuthService, private toastr: ToastrService) {
    this.resetpassword = this.formBuilder.group({
      newpassword: ["", [Validators.required, Validators.minLength(6)]],
      confirmpassword: ["", [Validators.required]],
    }, {
      validator: MustMatch("newpassword", "confirmpassword")
    });
  }
  ngOnInit(): void {
    const ref = this.route.snapshot.paramMap.get('token');
    this.apiService.checkLink(ref).subscribe((res: any) => {
      this.user = res.response;
      if (res.status == 1) {
        this.showForm = 1;
      }
      else if (res.status == 4) {
        this.showForm = 2;
        this.toastr.error("Invalid Link", "Please generate new link.");
        this.router.navigateByUrl('/');
      
      }
      else if (res.status == 5) {
        this.showForm = 2;
        this.toastr.error("Invalid Link", "Please generate new link.");
        this.router.navigateByUrl('/');
        
      }
    })
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }
  get f() {
    return this.resetpassword.controls;
  }
  onSubmit() {
    this.apiService.updatePassword(this.user.email, {
      password: this.resetpassword.value.newpassword
    }).subscribe((event: any) => {

      this.apiService.updateToken(this.token, {
        status: 0
      })
      if (event.status == 1) {
        this.toastr.success('Password Reset Successfully');
        this.router.navigateByUrl('/');
      }
      else if (event.status == 0) {
        this.toastr.error('Cannot Reset Password');
      }
      else if (event.status == 5) {
        this.toastr.error('Unable To Update');
      }
    })
  }
}
