import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    public apiService:ApiService,
  private toastr:ToastrService // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user,'signin');
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            this.router.navigate(['dashboard']);
          }
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user,'signup');
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any, type:any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        // this.router.navigate(['dashboard']);
        this.SetUserData(result.user, type);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any, type:any) {
    console.log(user);
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
    //   `users/${user.uid}`
    // );
    const userData: any = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };

if(type==='signin'){
    this.apiService.login({
      email: user.email,
      password: '',
      userType: '2',
      status: '1'
    }).subscribe(res => {

      this.apiService.saveToken(res.accessToken);
      if (res.status === 1) {
        this.toastr.success("Success", "Login Successful");
        const data = res.user;
        localStorage.setItem("status", res.status);
        localStorage.setItem("data", data)
        this.apiService.saveUser(data);
        localStorage.setItem("token", data.accessToken);

        let page = localStorage.getItem("page") || "";
        if (page == "plan") {
          this.router.navigate(["/planPricing"], {
            replaceUrl: false
          });
          localStorage.removetItem("page");
        } else {
          this.router.navigate(["/userDashboard"], {
            replaceUrl: false
          });
        }
      } else if (res.status === 3) {
        this.toastr.error('Invalid Password');
      }
      else if (res.status === 4) {
        this.toastr.error('Invalid Username, Please Signup First.');
      }
      else if (res.status === 5) {
        this.toastr.error('Unable to process');
      }
      else if (res.status === 6) {
        this.toastr.error('Please verify your email id');
      }
      else if (res.status === 7) {
        this.toastr.error('User Not Active');
      }
      else {
        this.toastr.error('You have registered successfully, Please signin.');
      }
    });
  }else {
    this.apiService.register({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: "",
      userType: "2",
      status: 1
    }).subscribe(res => {
      const data = res;
      if(data.status==1){
        this.toastr.success('User registeration successful, Please login');
      }
      if (data.status == 2) {
        this.toastr.error('Email Already Exists');
      }
      this.router.navigate(["/signIn"], {
        replaceUrl: true
      });
    });
  
  }
    // return userRef.set(userData, {
    //   merge: true,
    // });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }
}
