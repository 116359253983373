import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-my-plan',
  templateUrl: './my-plan.component.html',
  styleUrls: ['./my-plan.component.css'],
})
export class MyPlanComponent implements OnInit {
  user: any;
  profileForm: FormGroup;
  list: any;
  loggedIn: boolean = false;
  planShow = false
  constructor(
    private spinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private toastr: ToastrService
  ) {
    this.profileForm = this.formBuilder.group({
      package: ['', [Validators.required, Validators.minLength(2)]],
      packageDuration: ['', [Validators.required, Validators.minLength(2)]],
      packageName: ['', [Validators.required, Validators.minLength(2)]],
      packagePrice: ['', [Validators.required, Validators.minLength(2)]],
      packageStatus: ['', [Validators.required, Validators.minLength(2)]],
      dayCount: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  ngOnInit(): void {
    this.user = this.apiService.getUser();
    this.getUserPlan();
    this.DeactivedPlan();
    this.getUserSubscription();
  }
  get f() {
    return this.profileForm.controls;
  }
  onSubmit() { }
  getUserPlan() {
    this.spinnerService.show();
    this.apiService.getUserPlanById(this.user.id).subscribe((data) => {
      console.log(data)
      if (data.status == 404) {
        this.planShow = true
        this.spinnerService.hide();
      }
      else {
        if (data.length == 0) {
          this.spinnerService.hide();
        }
        this.planShow = false
        this.filterPlan(data)

        let available = 0,
          created = 0,
          pID = 0;
        data.forEach((pkg: any) => {
          if (pkg.numberofSite > pkg.websiteCount) {
            pID = pkg.id;
          }
          available = available + pkg.numberofSite;
          created = created + pkg.websiteCount;
        });

      }

      this.list = data;
      this.spinnerService.hide();

    });

  }
  getUserSubscription() {
    // this.spinnerService.show();
    this.apiService.getUserSubscriptions(this.user.stripe_customer_id).subscribe((data) => {
      console.log(data);
    });
  }
  activatePlan(id: any) {
    localStorage.setItem('planId', id);
  }

  DeactivedPlan() {
    //   if (this.list.daycount == 1)
    //     this.apiService.updatePackageStatus(this.user.id, {
    //       status: 1
    //     }).subscribe(data => {
    //       if (data.status == 1) {
    //         this.toastr.error('Your Plan has been expired')
    //       }
    //     })
    // }
  }
  activePlan: any
  filterPlan(planData: any) {
    const activeElement = planData.filter((plan: any) => plan.status === 1)
    console.log(activeElement)
    this.activePlan = activeElement
    return
  }
}
