import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { BlogComponent } from './blog/blog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { MessageComponent } from './message/message.component';
import { MyFavoriteComponent } from './my-favorite/my-favorite.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';
import { MyPlanComponent } from './my-plan/my-plan.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { FailureComponent } from './payment/failure/failure.component';
import { SuccessComponent } from './payment/success/success.component';
import { PlanPricingComponent } from './plan-pricing/plan-pricing.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { ResetComponent } from './reset/reset.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'planpricing', component: PlanPricingComponent },
  { path: 'userInfo', component: UserInfoComponent },
  { path: 'signIn', component: SigninComponent },
  { path: 'UserTemplates', component: UserPanelComponent },
  { path: 'signUp', component: SignupComponent },
  { path: 'userDashboard', component: UserDashboardComponent },
  // { path: 'blog', component: BlogComponent },
  { path: 'account', component: MyProfileComponent },
  { path: 'blog-detail/:id', component: BlogDetailComponent },
  { path: 'forgetpassword', component: ForgetPasswordComponent },
  { path: 'reset/:token', component: ResetComponent },
  { path: 'myplan', component: MyPlanComponent },
  { path: 'purchasePlan', component: PurchasePlanComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'failure', component: FailureComponent },
  { path: 'mypayment', component: MyPaymentComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: 'myfavorite', component: MyFavoriteComponent },
  { path: 'accVerification/:token', component: AccountVerificationComponent },
  { path: 'message', component: MessageComponent },
  { path: 'notification', component: NotificationListComponent },
  { path: 'terms-of-service', component: TermConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '404',
    pathMatch: 'full', component: ErrorPageComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
