import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css'],
})
export class AccountVerificationComponent implements OnInit {
  user: any;
  accVerifiactionForm!: FormGroup;
  submitted = false;
  loggedIn: boolean = false;
  token = '';
  showForm = 0;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.apiService.checkLink(this.token || token).subscribe((res: any) => {
        this.user = res.response;
        if (res.status == 1) {
          this.showForm = 1;
        } else if (res.status == 4) {
          this.showForm = 2;
          this.toastr.error(
            'Invalid Link',
            'Link expired please generate new link.'
          );
          this.router.navigateByUrl('/')
        } else if (res.status == 5) {
          this.showForm = 2;
         
          this.toastr.error(
            'Invalid Link',
            'Link expired please generate new link.'
          );
          this.router.navigateByUrl('/')
        } else if (res.status == 0) {
          this.showForm = 2;
          this.toastr.error('Invalid Link, Please regenerate verification link.');
          this.router.navigateByUrl('/')
        }
      });
    });
  

  }
  get f() {
    return this.accVerifiactionForm.controls;
  }
  onSubmit() {
    const userData = [{
      account_type: 'CUSTOMER',
      lang: "en",
      account_name: this.user.email, // REQUIRED ,UNIQUE 
      email: this.user.email,
    }, {
      status: 1
    }
    ]
    this.apiService
      .updateByEmail(this.user.email, userData)
      .subscribe((event: any) => {
        this.apiService
          .updateToken(this.user.token, {
            status: 1,
          })
          .subscribe((data) => { });

        if (event.status == 1) {
          this.toastr.success('Verification successfully completed, please login');
          this.router.navigateByUrl('/signIn');
        } else if (event.status == 0) {
          this.toastr.error('Please regenerate verification link');
        } else if (event.status == 5) {
          this.toastr.error('Unable to process');
        }
      });
  }
}
