<section class="normal-user-container">
    <app-header></app-header>
</section>
<!--Banner-->
<!-- <button (click)="getplans()">get all plans</button> -->
<section class="inner-body-container">
    <div class="container">
        <div class="inner_heading wow fadeInDown" data-wow-delay="0.2s">
            <h1>Plans & Pricing</h1>
            <p>Choose the plan that best fits to your need</p>
        </div>
        <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">  
            <p style="color: white">Please Wait. </p>  
            </ngx-spinner>
        <div class="plan-container mt-5">
            <div class="row justify-content-center">
                <div *ngFor="let item of packageList" class="col-md-6 col-lg-4 wow fadeInDown" data-wow-delay="0.2s">
                    <div class="Basic-plan Basic-body">
                        <div class="plan-head bsicplan-color">
                            {{item?.packageName}}
                        </div>
                        <div class="seprator-plan mt-5 mb-4"></div>
                        <div class="plan-included">
                            <ul class="list-unstyled">
                                <li *ngFor="let feature of item?.packageDetail">
                                    <div class="row">
                                        
                                        <span *ngIf="feature?.status=='1'" style="padding-right: 10px;">
                                            <!-- ✔    -->
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span *ngIf="feature?.status=='0'" style="padding-right: 10px;">
                                            <!-- ❌ -->
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </span>
                                         {{feature?.packageDetail}}
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div class="seprator-plan"></div>
                        <div class="plan-price text-center d-flex align-items-center justify-content-center">{{settingList?.currencySign}}{{item?.packagePrice}}/<span style="font-size: 20px; color: black;">
                            Per Month
                        </span>
                        </div>
                        <!-- <h4 *ngIf="item?.trialPeriod" class="text-center">Free {{item?.trialPeriod}} Days Trial</h4> -->
                        <div class="text-center" (click)="onSelect(item)">
                           
                            <a  class="btn btn-primary">Purchase Plan</a>
                        </div>
                        <div class="text-center" *ngIf="loginStatus==0">
                            <a routerLink="/UserTemplates" class="btn btn-primary">Signup Now</a>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="wow fadeInDown" data-wow-delay="0.2s">
                <h4>Plan terms & Condition</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and
                    more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
            </div> -->
        </div>

    </div>
</section>
<!--Footer-->
<app-footer></app-footer>