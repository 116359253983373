<section class="user-admin-sections">
    <app-header></app-header>
</section>
<!-- <mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">

    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>

    
</mat-drawer-container> -->
<div class="admin-full-panel">
    <div class="container">
        <div aria-label="breadcrumb" class="">
            <ol class="breadcrumb mb-0 pl-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Change Password</li>
            </ol>
        </div>
    </div>

    <div class="form my__profile mt-4" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="container" style="max-width:640px;">
            <div class="card border-0 shadow__cust">
                <div class="card-body">
                    <div class="form-group">
                        <label>*Current Password : </label>
                        <input type="password" class="form-control" placeholder="Enter Current Password" formControlName="currentPassword" />
                        <span class="text-danger" *ngIf="(f.currentPassword.touched || submitted) && f.currentPassword.errors?.required ">
                            Current Password is required
                        </span>
                    </div>
                    <div class="form-group">
                        <label>*New Password:</label>
                        <input type="password" class="form-control" placeholder="Enter New Password" formControlName="password" />
                        <span class="text-danger" *ngIf="(f.password.touched || submitted) && f.password.errors?.required ">
                            Last Name is required
                        </span>
                    </div>
                    <div class="form-group">
                        <label>*Confirm Password:</label>
                        <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Enter Confirm Password" />
                        <span class="text-danger" *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors?.required ">
                            Confirm Password required
                        </span>

                        <span *ngIf="f.confirmPassword.errors">Passwords must match</span>

                    </div>
                </div>

                <li class="list-inline-item mb-5" style="margin: auto;">
                    <button class="btn btn-success" (click)="onSubmit()">Change Password
                    </button>
                </li>
            </div>
        </div>
    </div>
</div>

<!--Footer-->
<app-footer></app-footer>