import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MustMatch } from "../helpers/mustMatchValidtor";
import { ApiService } from "../services/api.service";


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  user: any;
  userId = "";
  changePasswordForm: FormGroup;
  form: any = {};
  submitted=false;
  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private toaster: ToastrService) {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ["", [Validators.required, Validators.minLength(6)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required]]
    }, {
      validator: MustMatch("password", "confirmPassword")
    });
  }
  ngOnInit(): void {
    this.user = this.apiService.getUser();
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  onSubmit(): void {
    
    this.submitted = true;
    this.userId = this.user.id;
    this.apiService.changePassword(this.userId, {
      currentpassword: this.changePasswordForm.value.currentPassword,
      password: this.changePasswordForm.value.password,
    })
      .subscribe(
        response => {
          
        this.submitted = false;
          if (response.status == 1) {
            this.toaster.success('Password changed successfully');
            this.router.navigate(['/userDashboard']);
          } else if (response.status == 0) {
            this.toaster.error('Unable to process, Please retry');
          } else if (response.status == 3) {
            this.toaster.error('Invalid current password');
          } else if (response.status == 5) {
            this.toaster.error('Unable to process');
          }
        })
  }
}
