<section class="normal-user-container">
    <app-header></app-header>
</section>

<div aria-label="breadcrumb" class="">
    <ol class="breadcrumb mb-0">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/blog">Blog</a></li>
        <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
    </ol>
</div>
<ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>
<section class="blog-details-container">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-details-box shadow-sm rounded bg-white ">
                    <div class="upper-box wow fadeInDown ">
                        <h3>{{blogData?.blogTitle}}</h3>
                        <ul class="post-meta list-inline ">
                            <!-- <li class="list-inline-item "><span class="icon-share icons "></span>3 Share</li> -->
                            <li class="list-inline-item "><span class="icons icon-user "></span>Admin</li>
                        </ul>
                    </div>

                    <div class="blog-detail-image wow fadeInDown ">
                        <img src="{{blogData?.blogImage}} " alt=" ">
                        <div class="post-date">{{blogData?.createdAt | date:'dd MMM yyyy'}} </div>
                    </div>
                    <div class="lower-content wow fadeInDown " [innerHtml]="blogData?.body | sanitizeHtml">
                    </div>
                    <div class="post-like wow fadeInDown">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                Like This Post :-
                            </li> 
                            <li class="list-inline-item">
                                {{blogData?.count}} <a (click)="onSubmit(blogData?.id)" class="btn icons " >

                                    <i *ngIf="!like" class="far fa-thumbs-up" ></i>

                                    <i *ngIf="like" class="fas fa-thumbs-up" style="color:darkorange"></i>
                                </a>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </div>

            <div class=" col-lg-4 ">
                <div class="blog-right-box shadow-sm rounded bg-white p-3 ">
                    <h4>Recent Post</h4>
                    <div class="latest-post ">
                        <article class="recentpost-block wow fadeInDown" *ngFor="let blog of blogList" (click)="onSelect(blog.blogUrl)">
                            <figure class="recentpost-img">
                                <img src="{{blog?.blogImage}}" (click)="onSelect(blog.blogUrl)">
                                <a routerLink="/Blog-Detail " class="overlay-box "><span
                                        class="icon fa fa-link "></span></a>
                            </figure>
                            <div class="text " (click)="onSelect(blog.blogUrl)">
                                <a>{{blog.metaDescription}}</a>
                            </div>
                            <div class="post-info ">{{blog.createdAt | date}}
                            </div>
                        </article>
                    </div>
                    <h4 class="mt-4 ">Popular Tags</h4>
                    <div class="popular-tags wow fadeInDown ">
                        <a *ngFor="let item of keywords" (click)="getMetaTag(item.metaTag)">{{item?.metaTag}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Footer-->
<app-footer></app-footer>