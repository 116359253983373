<section class="normal-user-container">
    <app-header></app-header>
</section>

<!--Banner-->
<section class="inner-body-container">
<div class="container">
    <div class="inner_heading wow fadeInDown" data-wow-delay="0.2s">
    <h1>Get started with CLikimize</h1>
    <p>Enter your info below to create your free account.</p>
</div>


<div class="fillinfo-container wow fadeInDown" data-wow-delay="0.2s">
<div class="form-group">
    <label>Email*</label>
    <input class="form-control" placeholder="Enter Email" type="text">
</div>

<div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <label>First Name*</label>
            <input class="form-control" placeholder="Enter First Name" type="text">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label>Last Name*</label>
            <input class="form-control" placeholder="Enter Last Name" type="text">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <label>Password*</label>
            <input class="form-control" placeholder="Enter Password" type="password">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label>Confirm Password*</label>
            <input class="form-control" placeholder="Enter Confirm Password" type="text">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <label>Phone*</label>
            <input class="form-control" placeholder="Enter Phone" type="text">
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label>Have a website already?*</label>
            <select class="form-control">
                <option>Yes</option>
                <option>No</option>
            </select>
        </div>
    </div>
</div>

<p class="text-danger text-right font-italic"><small>* Fields are mandatory</small></p>

<div class="text-center mt-3 mb-4 info-btn"><button routerLink="/UserDashboard" class="btn btn-primary px-5">Submit</button></div>

</div>

</div>
</section>
<!--Footer-->
<app-footer></app-footer>