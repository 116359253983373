<section class="user-admin-sections">
    <app-header></app-header>
</section>
<div class="admin-full-panel">
    <div class="container">
        <div aria-label="breadcrumb" class="">
            <ol class="breadcrumb mb-0 pl-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Notification</li>
            </ol>
        </div>
    </div>
    <div class="form my__profile mt-4">
        <div class="container-lg mt-5 mypayment" style="max-width:1024px;">
            <h4 class="mb-4">Notifications</h4>
            <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
                <p style="color: white">Please Wait. </p>
            </ngx-spinner>
    
            <div class="notiBox card shadow border-0 mb-2" *ngFor="let list of list">
                <div class="card-body">
                    <h6>{{list?.content}}</h6>
                    <p class="mb-0">Type: <span>{{list?.type}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">
    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>
</mat-drawer-container> -->

<!--Footer-->
<app-footer></app-footer>