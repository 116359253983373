import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/mustMatchValidtor';
import { ApiService } from '../services/api.service';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";;
import { FacebookLoginProvider } from "angularx-social-login";
import * as auth from 'firebase/auth';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  manualSignupForm: FormGroup;
  submitted = false;
  user: SocialUser = new SocialUser;
  loggedIn: boolean = false;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private socialAuthService: SocialAuthService, private toastr: ToastrService, public socialService:AuthService, public afAuth: AngularFireAuth) {
    this.manualSignupForm = this.formBuilder.group({
      fName: ["", [Validators.required, Validators.minLength(2)]],
      lName: ["", [Validators.required, Validators.minLength(2)]],
      email: ["", [Validators.required, Validators.minLength(2)]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required]]
    }, {
      validator: MustMatch("password", "confirmPassword")
    });

   // this.signOut();
  }

  ngOnInit() {
    this.getSiteToken();
  }

  get f() {
    return this.manualSignupForm.controls;
  }
  getSiteToken() {
    this.apiService.siteToken().subscribe(data => {
      localStorage.setItem("token", data.accessToken);
      this.apiService.saveToken(data.accessToken);
    })
  }
  registerUser() {
    this.submitted = true;
    if (this.manualSignupForm.invalid) {
      return;
    }

    if (this.manualSignupForm.valid) {
      // console.table(this.manualSignupForm.value);
    }
    this.apiService.register({
      firstName: this.manualSignupForm.value.fName,
      lastName: this.manualSignupForm.value.lName,
      email: this.manualSignupForm.value.email,
      password: this.manualSignupForm.value.password,
      userType: "1",
      status: 0
    }).subscribe(res => {
      console.log(res)
      let status = res.status;
      if (status == 1) {
        this.createDudaUser(res.data)
        this.router.navigate(["/signIn"], {
          replaceUrl: true
        });
        this.toastr.success('User registeration successful, Please Verify Your Email');
      }
      else if (status == 2) {
        this.toastr.error('Email already exists')
      }
      else {
        this.toastr.error("Error", "" + res.message);
      }
    });

  }

signUpWithGoogle(){
  this.socialService.AuthLogin(new auth.GoogleAuthProvider(),'signup').then((x:any) => {
  });}

  signUpWithGoogleOld(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      this.apiService.register({
        firstName: x.firstName,
        lastName: x.lastName,
        email: x.email,
        password: "",
        userType: "2",
        status: 1
      }).subscribe(res => {
        const data = res;
        if(data.status==1){
          this.toastr.success('User registeration successfully, Please login');
        }
        if (data.status == 2) {
          this.toastr.error('Email Already Exists');
        }
        this.router.navigate(["/signIn"], {
          replaceUrl: true
        });
      });
    });
  }

  signUpWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x =>
      this.apiService.register({
        firstName: x.firstName,
        lastName: x.lastName,
        email: x.email,
        password: "",
        userType: "3",
        status: 1
      }).subscribe(data => {
        const res = data;
        if(data.status==1){
          this.toastr.success('User registeration successfully, Please login.');
        }
        if (data.status == 2) {
          this.toastr.error('Email Already Exists');
        }
        this.router.navigate(["/signIn"], {
          replaceUrl: true
        });
      }));
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }


  createDudaUser(user:any){
  const userData= {
      account_type: 'CUSTOMER',
      first_name: user.firstName,
      last_name: user.lastName,
      lang: "en",
      account_name: user.email, // REQUIRED ,UNIQUE
     
      email: user.email,
      company_name:""
  }
  this.apiService.createDudaUser(userData).subscribe((response: any)=> {
    console.log(response)
  })
}
}
