import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';

import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


const baseUrl = environment.baseUrl;
const TOKEN_KEY = 'token';
const USER_KEY = 'user';
let dudaUrl = environment.dudaUrl;


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  ipAddress = '';
  purchagePackageDetails = new BehaviorSubject<any>([]);


  constructor(private http: HttpClient) { }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem("token") || "",
    })
  };

  // options = {
  //   headers: new HttpHeaders({
  //     accept: 'application/json',
  //     authorization: "Basic YTQwYjUyNDhmMDpyYWNaWW9yNzdLN24=",

  //   })
  // };

  dudaHttpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic YTQwYjUyNDhmMDpyYWNaWW9yNzdLN24=',
    })
  };

  login(data: any): Observable<any> {
    // console.log(data)
    return this.http.post(baseUrl + 'user/login', {
      email: data.email,
      password: data.password,
      userType: data.userType,
      status: data.status
    }, this.httpOptions);
  }

  register(user: any): Observable<any> {
    // debugger
    // console.table(user)
    this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    })
    return this.http.post(baseUrl + 'user/register', user, this.httpOptions);
  }
  forgetPassword(user: any): Observable<any> {
    return this.http.post(baseUrl + 'auth/forgetpassword', {
      uEmail: user.uEmail
    }, this.httpOptions);
  }

  pushFileToStorage(file: File, type: any): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    let url = '';
    if (type == 1) {
      url = 'http://localhost:8080/settings/updatelogo';
    } else
      if (type == 2) {
        url = 'http://localhost:8080/settings/updatefavicon';
      }

    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);
  }

  updateSetting(id: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'settings/update'}`, data, this.httpOptions);
  }


  changePassword(id: any, data: any): Observable<any> {
    return this.http.post(baseUrl + 'user/updatepassword/' + id, data, this.httpOptions);
  }

  createContactUs(cantact: any): Observable<any> {
    return this.http.post(baseUrl + 'contactus/save', {
      firstName: cantact.firstName,
      lastName: cantact.lastName,
      email: cantact.email,
      message: cantact.message,
      mobileNumber: cantact.mobileNumber
    }, this.httpOptions);
  }

  createSubscribe(subscribe: any): Observable<any> {
    return this.http.post(baseUrl + 'newslatter/save', {
      email: subscribe.email,
      status: subscribe.status
    }, this.httpOptions);
  }

  signOut() {
    localStorage.removeItem('token')
    localStorage.clear();
  }
  public saveToken(token: string) {
    // localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY) || "";
  }

  public saveUser(user: any) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY) || "{}");
  }

  getAllPackage(): Observable<any> {
    return this.http.get(baseUrl + 'packagedetail/getActivePackage', this.httpOptions);
  }

  getAllBlogList(): Observable<any> {
    return this.http.get(baseUrl + 'blog/getpublish', this.httpOptions);
  }
  getBlogByUrl(blogUrl: any): Observable<any> {
    return this.http.get(baseUrl + 'blog/getByBlogUrl/' + blogUrl, this.httpOptions);
  }
  //user like by id
  getUserLikeBlogById(id: any): Observable<any> {
    return this.http.get(baseUrl + 'like/getUserLikeBlogByid/' + id, this.httpOptions);
  }
  getRecordByMetaTag(metaTag: any): Observable<any> {
    return this.http.get(`${baseUrl + 'blog/getBlogByTag'}/${metaTag}`, this.httpOptions);
  }

  getSiteSetting(): Observable<any> {
    return this.http.get(baseUrl + 'settings/getsitesetting', this.httpOptions);
  }

  likeBlog(like: any): Observable<any> {
    return this.http.post(baseUrl + 'like/save', {
      tblUserId: like.tblUserId,
      tblBlogId: like.tblBlogId,
      status: 1,
    }, this.httpOptions);
  }

  disLikeBlog(userid: any, blogid: any, data: any): Observable<any> {
    return this.http.post(baseUrl + 'like/update/' + userid + "/" + blogid, data, this.httpOptions);
  }
  getLikeBloglist(): Observable<any> {
    return this.http.get(baseUrl + 'like/getall', this.httpOptions);
  }
  checkLink(ref: any): Observable<any> {
    return this.http.get(baseUrl + 'user/checkLink/' + ref, this.httpOptions);
  }
  resetPassword(user: any): Observable<any> {
    return this.http.post(baseUrl + 'user/forgetpassword', {
      email: user.email,
    }, this.httpOptions);
  }
  updatePassword(email: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'user/update'}/${email}`, data, this.httpOptions);
  }
  updateUpdateStatus(id: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'user/update'}/${id}`, data, this.httpOptions);
  }
  updateUser(id: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'user/updateById'}/${id}`, data, this.httpOptions);
  }

  updateProfileImage(id: any, file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('profile', file);
    const req = new HttpRequest('POST', baseUrl + 'user/updateprofile/' + id, formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }
  getUserPlanById(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'user/getplanByUserId'}/${id}`, this.httpOptions);
  }
  getUserSubscriptions(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'transaction/get-customer-subscriptions'}/${id}`, this.httpOptions);
  }

  purchasePlan(purchase: any): Observable<any> {
    return this.http.post(baseUrl + 'userpackage/save', {
      tblUserId: purchase.tblUserId,
      tblPackageDetailId: purchase.tblPackageDetailId,
      startDate: purchase.startDate,
      endDate: purchase.endDate
    }, this.httpOptions);
  }

  paymentApi(payment: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/payment', {
      tblUserId: payment.tblUserId,
      Amount: payment.Amount,
      paymentMode: payment.paymentMode,
      status: payment.status,
      transactionRef: payment.transactionRef,
      transactionId: payment.transactionId
    }, this.httpOptions);
  }


  getPaymentToken(payment: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/getPaymentToken', payment, this.httpOptions);
  }


  getSessionData(payment: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/retrieveSession', payment, this.httpOptions);
  }

  createNotification(notify: any): Observable<any> {
    return this.http.post(baseUrl + 'notification/save', {
      title: notify.title,
      content: notify.content,
      type: notify.type,
      status: notify.status,
    }, this.httpOptions);
  }

  //Add To Favorite templates
  createAddToFavorite(favi: any): Observable<any> {
    return this.http.post(baseUrl + 'addtofavorite/save', {
      tblUserId: favi.tblUserId,
      templateId: favi.templateId,
      status: favi.status,
    }, this.httpOptions);
  }
  createWebSite(web: any): Observable<any> {
    return this.http.post(baseUrl + 'website/save', web, this.httpOptions);
  }

  getUserFavoriteList(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'addtofavorite/getUserFavoriteById'}/${id}`, this.httpOptions);
  }
  getTemplateByCategory(category: any): Observable<any> {
    return this.http.get(baseUrl + 'template/getCategaryFilter/' + category, this.httpOptions);
  }

  getTemplateList(): Observable<any> {
    return this.http.get(baseUrl + 'template/getAllTemplate', this.httpOptions);
  }
  getTemplateByColorFilter(color: any): Observable<any> {
    return this.http.get(baseUrl + 'template/getColorFilter/' + color, this.httpOptions);
  }

  getMyWebsiteList(id: any): Observable<any> {
    return this.http.get(baseUrl + 'website/getPermission/' + id, this.httpOptions);
  }
  deleteWebSite(site_name: any): Observable<any> {
    return this.http.delete(baseUrl + 'website/delete/' + site_name, this.httpOptions)
  }
  resetWebSite(site_name: any, body: any): Observable<any> {
    return this.http.post(baseUrl + 'website/resteSite/' + site_name, body, this.httpOptions)
  }
  duplicateWebSite(body: any): Observable<any> {
    return this.http.post(baseUrl + 'website/duplicateWebsite ', body, this.httpOptions)
  }
  accVerification(email: any, web: any): Observable<any> {
    return this.http.post(baseUrl + 'user/update/' + email, {
      status: web.status
    }, this.httpOptions)
  }
  renameSiteName(id: any, web: any): Observable<any> {
    return this.http.post(baseUrl + 'website/siteRename/' + id, {
      siteName: web.siteName
    }, this.httpOptions)
  }

  updateToken(token: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'user/updateToken'}/${token}`, data, this.httpOptions);
  }
  updatePackageStatus(id: any, data: any): Observable<any> {
    return this.http.post(`${baseUrl + 'userpackage/update'}/${id}`, data, this.httpOptions);
  }
  getPackgeById(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'packagedetail/getPackgeById'}/${id}`, this.httpOptions);
  }
  getMyTrasaction(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'transaction/getTransactionByUserId'}/${id}`, this.httpOptions);
  }
  siteToken(): Observable<any> {
    return this.http.get(baseUrl + 'token/getSettingsToken', this.httpOptions);
  }
  getUserNotification(id: any): Observable<any> {
    return this.http.get(`${baseUrl + 'usernotification/getUserNotification'}/${id}`, this.httpOptions);
  }
  createMessage(data: any): Observable<any> {
    return this.http.post(baseUrl + 'message/save', data, this.httpOptions);
  }
  userMessageList(id: any): Observable<any> {
    return this.http.get(baseUrl + 'message/getMessage/' + id, this.httpOptions);
  }
  updateNofication(id: any, data: any): Observable<any> {
    return this.http.post(baseUrl + 'usernotification/update/' + id, data, this.httpOptions);
  }
  notificationList(id: any): Observable<any> {
    return this.http.get(baseUrl + 'usernotification/notificationlist/' + id, this.httpOptions);
  }

  getProfile(id: any): Observable<any> {
    return this.http.get(baseUrl + 'user/getById/' + id, this.httpOptions);
  }
  updateByEmail(email: any, status: any): Observable<any> {
    return this.http.post(baseUrl + 'user/verifyUser/' + email, status, this.httpOptions);
  }

  createCustomer(user: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/create-customer', user, this.httpOptions);
  }
  createSubscription(params: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/create-subscription', params, this.httpOptions);
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${baseUrl}create-payment-intent`,
      { amount }
    );
  }
  /*************************************************************************
   * DUDA API
   *          getTransactionByUserId
   *  getColorFilter
   * 
   * 
   * ***********************************************************************/


  // public getDudaTemplates() {
  //   return this.http.get('https://clickimize.com/dudaAPI/index.php');
  // }



  // change by Anand
  purchagePlan(payment: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/save', payment, this.httpOptions);
  }

  loginResponse = new Subject();

  // duda apis
  getDudaUser(accountName: any) {
    return this.http.get(baseUrl + 'website/getUser/' + accountName, this.dudaHttpOptions)
  }

  createDudaUser(userData: any) {
    return this.http.post(baseUrl + 'website/createDudaUser/', userData, this.dudaHttpOptions)
  }

  SooLogin(body: any) {
    return this.http.post(baseUrl + 'website/getSOOLogin/', body, this.dudaHttpOptions)
  }
  authBuilder() {
    return this.http.get('https://builder.clickimize.com/api/ms/auth?siteAlias=c63e88e5', this.httpOptions)
  }
  dudaGeneratePassword(email: any) {
    return this.http.post(baseUrl + 'website/generateDudaPasswordLink/', { email }, this.dudaHttpOptions)
  }

  createPaymentSession(params: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/create-checkout-session', params, this.httpOptions);
  }
  createSubscribtionPaymentSession(params: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/create-subscribed-checkout-session', params, this.httpOptions);
  }

  publishWebsite(siteId: any): Observable<any> {
    return this.http.post(baseUrl + 'website/publishWebsite/', { siteId }, this.httpOptions);
  }
  getstripePlans(): Observable<any> {
    return this.http.get(baseUrl + 'transaction/getallPlans', this.httpOptions);

  }
  getPaymentDetail(sessionId: any): Observable<any> {
    return this.http.post(baseUrl + 'transaction/stripePaymentDetails', { sessionId }, this.httpOptions);

  }
  updatePublishedWebsiteCount(site_id: any): Observable<any> {
    return this.http.post(baseUrl + 'website/updateWebsitePublishCount/', { site_id }, this.httpOptions)
  }
  allpassss(): Observable<any> {
    return this.http.get(baseUrl + 'transaction/alltranscation', this.httpOptions)
  }
  templateorder(order: any) {
    return this.http.get(baseUrl + 'template/templateorder/' + order, this.httpOptions)
  }
  UpdateTransation(userId: any) {
    return this.http.get(baseUrl + 'transaction/alltranscation/'+userId, this.httpOptions)
  
  }
}
