import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ToastrService } from "ngx-toastr";
import { ApiService } from "../services/api.service";
import { SortCriteria } from "../sort.pipe";
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.css']
})
export class UserPanelComponent implements OnInit {
  status: boolean = false;
  templatesList: any;
  id: any;
  currentSelection = "all";
  user: any;
  favList: [] = [];
  favValue: [] = [];
  template: any;
  form: any = {};
  userForm!: FormGroup;
  myFav: Array<any> = [];
  tempList: [] = [];
  iconPath: any;
  searchText: any;
  webList: any;
  public criteria!: SortCriteria;
  readioSelected: any;
  showContent: boolean = false;
  siteName: any;
  planId: any;
  available = 0;
  created = 0;

  pID = 0;
  submitted = false;
  data = false;
  errorMessage: any;
  clickEvent() {
    this.status = !this.status;
  }
  isLoggedIn = false;
  constructor(private spinnerService: NgxSpinnerService, private router: Router, private viewPortScroller: ViewportScroller,
    private formBuilder: FormBuilder, private apiService: ApiService, private toaster: ToastrService, private modalService: NgbModal, private cdr: ChangeDetectorRef) {
    this.criteria = {
      property: 'name',
      descending: false
    };
    this.userForm = this.formBuilder.group({
      webSite: ["", [Validators.required]],
      siteUrl: [""],
      websiteName: ["", [Validators.required]]
    })
    this.viewPortScroller.scrollToPosition([0, 0]);
  }
  showHideTextBox() {
    let value = this.userForm.value.webSite
    // if (value == 'yes') {
    //   this.showContent = true
    // }
    this.showContent = value === "yes" ? true : false
  }
  ngOnInit(): void {
    this.getUser();

    this.getTemplteList();
    this.user = this.apiService.getUser();
    this.getUserPlan();
    console.log(this.user);
    if (this.user?.id > 0) {
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
    this.cdr.detectChanges();
  }

  getUserPlan() {
    this.apiService.getUserPlanById(this.user?.id).subscribe((data: any) => {
      console.log(data)
      if (!data) {

      } else {
        console.log(data)
        data.forEach((pkg: any) => {
          // if (pkg.numberofSite > pkg.websiteCount) {
          //   this.pID = pkg.id;
          // }
          if (pkg.status == 1) {
            this.pID = pkg.tblPackageDetailId;
          }
          console.log(this.pID)
          // this.pID = pkg.id;
          this.available = this.available + pkg.numberofSite;
          this.created = this.created + pkg.websiteCount;
        });
      }


    })
  }

  filterFun(index: any, url: any) {
    this.templatesList.find((d: {
      id: number
    }) => d.id === index);
    this.templatesList[index].image = url;
  }
  get f() {
    return this.userForm.controls;
  }
  getFilterTemplate(type: any) {
    this.viewPortScroller.scrollToPosition([0, 0])
    this.currentSelection = type;
    this.spinnerService.show();
    if (this.user.id != undefined || this.user.id != null) {
      this.apiService.getUserFavoriteList(this.user.id).subscribe(data => {
        this.favList = data;
      });

    }

    this.apiService.getTemplateList().subscribe((res: any) => {
      this.spinnerService.hide();
      if (res.status == 1) {
        this.data = true;
        this.templatesList = res.data;
        this.favValue = this.favList;
        this.template = this.templatesList;
        let a2: any[] = [];
        if (this.favValue.length > 0) {
          this.favValue.forEach((element: { templateId: number; }, index: number) => {
            let array2 = this.template.filter((d: { template_id: number }) => d.template_id === element.templateId);
            this.templatesList[index].favourite = true;
            a2.push(array2);

          });
          this.myFav = a2;
          var merged = [].concat.apply([], this.myFav);
          this.myFav = merged;
          this.data = true;
        }
        this.templatesList = this.myFav;
        this.templatesList.forEach((element: any, index: number) => {
          this.templatesList[index].image = element.iconPath;
        });
      } else { this.data = false; }

    }, err => {
      this.spinnerService.hide();
      this.data = false;
    })
  }

  getTemplteList() {
    this.spinnerService.show();
    if (this.user?.id != undefined || this.user?.id != null) {
      this.apiService.getUserFavoriteList(this.user.id).subscribe((data: any) => {
        if (data) {
          this.favList = data;
        }
        else {
          //this.favValue = [];
        }
      });

    }

    this.apiService.getTemplateList().subscribe((res: any) => {
      this.spinnerService.hide();
      if (res.status == 1) {
        this.data = true;
        this.templatesList = res.data;
        this.favValue = this.favList;
        console.log(this.favList)
        this.template = this.templatesList;
        if (this.favValue.length > 0) {
          this.favValue.forEach((element: { templateId: number; }, index: number) => {
            let array2 = this.template.filter((d: { template_id: number }) => d.template_id === element.templateId);
            this.templatesList[index].favourite = true;
            this.myFav.push(array2);
          });
          this.data = true;
        }
        this.templatesList.forEach((element: any, index: number) => {
          this.templatesList[index].image = element.iconPath;
        });
      } else { this.data = false; }

    }, err => {
      this.spinnerService.hide();
      this.data = false;
    })
  }

  showTemplates(type: any) {
    this.currentSelection = type;
  }
  getUser() {
    this.user = this.apiService.getUser();
  }
  templateAddToFavi(template_id: any) {
    let loginToken: any = localStorage.getItem('user')
    if (!loginToken) {
      this.toaster.error('Please login then item add to favorite')
    }
    if (loginToken) {
      this.apiService.createAddToFavorite({
        tblUserId: this.user.id,
        templateId: template_id,
        status: 1,
      }).subscribe(data => {
        if (data.status == 1) {
          this.ngOnInit();
          this.getTemplteList();
          this.toaster.success('Template added to favorite list');
        } else if (data.status == 5) {
          this.toaster.error('Unable to process');
        } else if (data.status == 2) {
          this.toaster.error('Favorite is already in list');
        }
      })
    }

  }
  templateFilter(color: any) {
    this.viewPortScroller.scrollToPosition([0, 0]);

    this.currentSelection = color;
    this.spinnerService.show();
    this.apiService.getTemplateByColorFilter(color).subscribe(data => {
      this.spinnerService.hide();
      if (data.status == 1) {
        this.templatesList = data.data;
        this.templatesList.forEach((element: any, index: number) => {
          this.templatesList[index].image = element.iconPath;
          this.data = true;
        })
      }
      else if (data.status == 4) {
        this.data = false;
        this.errorMessage = "No Template Found!";
      }

    }, err => {
      this.spinnerService.hide();
      this.data = false;
    })
  }

  categoryFilter(category: any) {
    this.viewPortScroller.scrollToPosition([0, 0])
    this.currentSelection = category;
    this.spinnerService.show();
    this.apiService.getTemplateByCategory(category).subscribe(data => {
      this.templatesList = data.data;
      this.templatesList.forEach((element: any, index: number) => {
        this.templatesList[index].image = element.iconPath;
      });
      this.spinnerService.hide();
      this.data = true;
    }, err => {
      this.spinnerService.hide();
      this.data = false;
    })
  }

  startBuildSite() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    console.log("pID", this.pID)
    console.log("User", this.user)

    // checking if user is login
    if (this.user?.id > 0) {
      // if(this.pID > 0){
      const body = {
        websiteName: this.userForm.value.websiteName,
        packageDetailId: this.pID,
        userId: this.user.id,
        status: 0,
        default_domain_prefix: "sub-domain",
        "template_id": this.template_id,
        "site_data": {
          "site_domain": "",
          "site_business_info": {
            "business_name": this.user.firstName + " " + this.user.lastName,
            "phone_number": this.user.mobileNumber,
            "email": this.user.email,
            "address": this.user.address,
          }
        },
        "site_alternate_domains": {
          "domains": this.userForm.value.siteUrl,
          "is_redirect": true
        },
        "site_seo": {
          "og_image": this.iconPath,
          "title": "Example Title",
          "description": "Example description. Should be around 155 characters long, but can be upto 320."
        }
      }
      this.modalService.dismissAll();

      this.apiService.createWebSite(body).subscribe((data) => {
        console.log(data)
        this.spinnerService.hide();
        this.webList = data.data;

        if (data.status == 1) {
          this.router.navigate(['/userDashboard'])
          this.toaster.success('Website created successfully');
        } else if (data.status == 5) {
          this.toaster.error('Unable to proccess');
        } else if (data.status = 6) {
          this.toaster.error('Reached maximum site limit purchase a new plan');
          // this.router.navigateByUrl('/planPricing');
        }
      })

      // }
    } else {
      this.modalService.dismissAll();
      this.toaster.error('Please login');
      this.router.navigateByUrl('/signIn');
    }


    // if (this.pID > 0) {
    // const body = {
    //   packageDetailId: this.pID,
    //   userId: this.user.id,
    //   status: 0,
    //   default_domain_prefix: "sub-domain",
    //   "template_id": this.template_id,
    //   "site_data": {
    //     "site_domain": "",
    //     "site_business_info": {
    //       "business_name": this.user.firstName + " " + this.user.lastName,
    //       "phone_number": this.user.mobileNumber,
    //       "email": this.user.email,
    //       "address": this.user.address,
    //     }
    //   },
    //   "site_alternate_domains": {
    //     "domains": this.userForm.value.siteUrl,
    //     "is_redirect": true
    //   },
    //   "site_seo": {
    //     "og_image": this.iconPath,
    //     "title": "Example Title",
    //     "description": "Example description. Should be around 155 characters long, but can be upto 320."
    //   }
    // }
    // if (this.user?.id > 0) {
    //   // console.table(body);
    //   this.apiService.createWebSite(body).subscribe((data) => {
    //     this.spinnerService.hide();
    //     this.modalService.dismissAll();
    //     this.webList = data.data;

    //     if (data.status == 1) {
    //       this.router.navigate(['/userDashboard'])
    //       this.toaster.success('Website created successfully');
    //     } else if (data.status == 5) {
    //       this.toaster.error('Unable to proccess');
    //     } else if (data.status = 6) {
    //       this.toaster.error('Reached maximum site limit purchase a new plan');
    //       // this.router.navigateByUrl('/planPricing');
    //     }
    //   })
    // } else {
    //   this.toaster.error('Please login');
    //   this.router.navigateByUrl('/signIn');
    // }
    // }
    // else {
    //   this.modalService.dismissAll();
    //   this.toaster.error('Please purchase plan & select plan');
    //   this.router.navigateByUrl('/planPricing');
    // }
  }
  template_id: any;
  openLg(content: any, data: any) {
    this.id = data.id;
    this.iconPath = data.iconPath;
    this.siteName = data.name;
    this.template_id = data.template_id;
    //if (this.user.id > 0) {
    this.userForm.patchValue({
      siteName: "" + data.name,
      iconPath: "" + data.iconPath,
      tabletIconPath: "" + data.tabletIconPath
    })
    this.modalService.open(content, {
      size: 'lg'
    });
    // } else {
    //   this.toaster.error('Please login');
    //   this.router.navigateByUrl('/signIn');
    // }

  }

  dismissModal() {
    this.showContent = false;
    this.modalService.dismissAll();
  }
  assendingOrder(e: any) {
    this.spinnerService.show();
    this.apiService.templateorder(e).subscribe((res: any) => {
      if (res.status == 1) {
        this.data = true;
        this.templatesList = res.data;
        this.favValue = this.favList;
        this.spinnerService.hide();
        this.template = this.templatesList;
        if (this.favValue.length > 0) {
          this.favValue.forEach((element: { templateId: number; }, index: number) => {
            let array2 = this.template.filter((d: { template_id: number }) => d.template_id === element.templateId);
            this.templatesList[index].favourite = true;
            this.myFav.push(array2);
          });
          this.data = true;
        }
        this.templatesList.forEach((element: any, index: number) => {
          this.templatesList[index].image = element.iconPath;
        });
      } else { this.data = false; }

    })
  }

}


export interface Template {
  id: number,
  packageDetail: any,
  packagePrice: number,
  packageDuration: string,
  packageName: string,
}
