<section class="user-admin-sections">
    <app-header></app-header>
</section>
<mat-drawer-container class="pt-3 pb-5" autosize style="padding-top: 70px !important;">
    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>
    <!-- <button class="toggle-sidenav" type="button" mat-button (click)="drawer.toggle()">
        <i class="icons"></i>
    </button> -->
    <div *ngIf="showForm === 1" class="email__verify" (ngSubmit)="onSubmit()">
        <div class="verify-button">
            <button class="btn btn-success " style="margin: auto;align-items: center; display: block;" type="submit" (click)="onSubmit()">Click  here to verify</button>
        </div>
    </div>


    <div class="email__verify" *ngIf="showForm == 2">
        <div class="verify-button text-center">
            <a class="btn btn-primary" routerLink="/signIn"> Go back to login</a>
        </div>
    </div>

</mat-drawer-container>

<!--Footer-->
<app-footer></app-footer>