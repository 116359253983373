<!-- <div class="container">
    <h2 class="mt-5 mb-4">Clickimize Stripe Testing</h2>

    <div class="col-md-5 mb-2">
        <button (click)="makePayment(15)" class="btn btn-danger btn-block">Pay $15</button>
    </div>
    <div class="col-md-5 mb-2">
        <button (click)="makePayment(25)" class="btn btn-primary btn-block">Pay $25</button>
    </div>
    <div class="col-md-5">
        <button (click)="makePayment(35)" class="btn btn-success btn-block">Pay $35</button>
    </div>
</div>
<div>

    <div class="col-md-5 mb-2">
        <button (click)="next(1)" class="btn btn-primary btn-block">Success</button>
    </div>
    <div class="col-md-5">
        <button (click)="next(0)" class="btn btn-success btn-block">Failed</button>
    </div>
</div> -->
<!-- 
<form id="payment-form">
    <div id="card-element"> -->
        <!-- Elements will create input elements here -->
    <!-- </div> -->

    <!-- We'll put the error messages in this element -->
    <!-- <div id="card-errors" role="alert"></div>

    <button id="submit">Submit Payment</button>
</form> -->
<div class="payment-card">
    <div class="payment__pay">
        <div class="text-center mb-4">
            <img src="../../assets/images/logo.png" style="width:50%"/> 
        </div>
        <div class="payment-container">
            <!-- <div class="text-center payment-head">
                <img src="https://api.clickimize.com/logo/file_1613541334744.png" style="width:50%"/> 
            </div> -->
            <div class="pay-padd">
                <h2 class="text-center">Purchase Plan</h2>
            <!-- <div [formGroup]="stripeTest">
            <input formControlName="name" />
            <input formControlName="amount" /> -->
            <!-- <ngx-stripe-card
                [options]="cardOptions"
                [elementsOptions]="elementsOptions" 
            ></ngx-stripe-card> -->

            <div class="row mt-4">
                <div class="col-md-8">
                    <h4>{{planDetails.packagename}}</h4>
                    <ul class="list-unstyled" >
                        <li *ngFor="let detail of packageDetail"><img src="../../assets/images/arrow-ri.svg"/> {{detail.packageDetail}}</li>
                        <!-- <li><img src="../../assets/images/arrow-ri.svg"/> Hosted on Amazon Web Services</li>
                        <li><img src="../../assets/images/arrow-ri.svg"/> Free SSL</li>
                        <li><img src="../../assets/images/arrow-ri.svg"/> 2 Hours of Support</li> -->
                    </ul>
                </div>
                <div class="col-md-4">
                    <div class="p-amt-info">
                        <h4>Amount: ${{planDetails.pkgAmt}}</h4>
                        <a routerLink='/planpricing' class="btn btn-outline-primary mt-2">Change Plan</a>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <button type="submit" (click)="pay1()" class="btn btn-primary pay-btn">PAY</button>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="recurringpay" [checked]="recurringPayment" (change)="recurringPayment = !recurringPayment">
                    <label class="form-check-label" for="recurringpay">Subscribe for recurring payments</label>
                </div>
            </div>
            <!-- <div> -->
                
                <!-- <input type="checkbox" [checked]="recurringPayment" (change)="recurringPayment = !recurringPayment"/>
                <label for="recurringPayment">Subscribe for recurring payments</label> -->
            <!-- </div> -->
            <!-- </div> -->
            </div>
        </div>
    </div>
</div>
