<section class="user-admin-sections">
  <app-header></app-header>
</section>
<div class="admin-full-panel">
  <div class="container-lg" style="max-width:1280px;">
    <div aria-label="breadcrumb" class="">
      <ol class="breadcrumb mb-0 pl-0">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">My Plan</li>
      </ol>
    </div>
  </div>

  <div class="form my__profile" [formGroup]="profileForm" (ngSubmit)="onSubmit()">

    <div class="container-lg mt-5 mypayment" style="max-width:1280px;">
      <p *ngIf="planShow" style="text-align: center;" class="text-danger">No Active Plan</p>
      <h5 *ngIf="!planShow" >Active Plan</h5>

      <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
      </ngx-spinner>
      <div *ngIf="activePlan && !planShow">
        <div class="card shadow avtive__plan my-4" *ngFor="let list of activePlan">
          <div class="card-body" (click)="activatePlan(list.id)">
            <div class="d-md-flex flex-wrap align-items-start mb-4">
              <div class="planname flex-fill">
                <h6>{{list?.packageName}}</h6>
                <span class="plantype">Package Type:{{list?.packageType}}</span>
              </div>
              <div class="d-sm-flex ml-auto planrightsec align-items-center">
                <span class="planamt">Amount: ${{list?.packagePrice}}</span>

                <span *ngIf="list?.daycount>=0 && list?.packageStatus===1" class="plandays">Remaining {{list?.daycount}}
                  days</span>
                <span *ngIf="list?.daycount< 0 && list?.packageStatus===0" class="plandays bg-danger">Expired</span>
                <span class="planstatus" *ngIf="list?.packageStatus===1">Status: Active</span>
                <span class="planstatus" *ngIf="list?.packageStatus===0">Status: Deactive</span>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>Created Site</span>
                  <p class="planClass">{{list?.websiteCount}}</p>
                </div>
              </div>
              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>Remaining Site</span>
                  <!-- Site only per package -->
                  <p class="planClass">{{ list?.numberofSite - list?.websiteCount }} </p>
                </div>
              </div>
              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>Site In Package</span>
                  <p class="planClass">{{list?.numberofSite}}</p>
                </div>
              </div>

              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>Package Duration</span>
                  <p>{{list?.packageDuration / 30}} Month Reccuring</p>
                </div>
              </div>

              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>Start Date</span>
                  <p>{{list?.startDate | date}}</p>
                </div>
              </div>

              <div class="col-6 col-md-2">
                <div class="transaction-de">
                  <span>End Date</span>
                  <p>{{list?.endDate | date}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <!-- olderplans -->

      <h5 class="mb-2" *ngIf="!planShow">All Plan</h5>
      <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
      </ngx-spinner>

      <div class="card shadow border-0 my-3" *ngFor="let list of list">
        <div class="card-body" (click)="activatePlan(list?.id)">
          <div class="d-md-flex flex-wrap align-items-start mb-4">
            <div class="planname flex-fill">
              <h6>{{list?.packageName}}</h6>
              <span class="plantype">Package Type:{{list?.packageType}}</span>
            </div>
            <div class="d-sm-flex ml-auto planrightsec align-items-center">
              <span class="planamt">Amount: ${{list?.packagePrice}}</span>
              <span *ngIf="list?.daycount>=0 && list?.packageStatus===1" class="plandays">Remaining {{list?.daycount}}
                days</span>
              <span *ngIf="list?.daycount< 0 && list?.packageStatus===0" class="plandays bg-danger">Expired</span>
              <span class="planstatus" *ngIf="list?.packageStatus===1">Status: Active</span>
              <span class="planstatus bg-danger" *ngIf="list?.packageStatus===0">Status: Deactive</span>
            </div>
          </div>

          <div class="row">
            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>Created Site</span>
                <p class="planClass">{{list?.websiteCount}}</p>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>Remaining Site</span>
                <p class="planClass">{{ list?.numberofSite - list?.websiteCount }}</p>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>Site In Package</span>
                <p class="planClass">{{list?.numberofSite}}</p>
              </div>
            </div>

            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>Package Duration</span>
                <p>{{list?.packageDuration / 30}} Month Reccuring</p>
              </div>
            </div>

            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>Start Date</span>
                <p>{{list?.startDate | date}}</p>
              </div>
            </div>

            <div class="col-6 col-md-2">
              <div class="transaction-de">
                <span>End Date</span>
                <p>{{list?.endDate | date}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <!-- activeplan -->
    <!-- <h5>Active Plan</h5>
    <table class="table table-hover" *ngIf="activePlan">
      <thead>
        <tr>
          <th scope="col">Package Name</th>
          <th scope="col">Package Price</th>
          <th scope="col">Package Type</th>
          <th scope="col">Package Status</th>
          <th scope="col">Created Site</th>
          <th scope="col">Remaining Site</th>
          <th scope="col">Site In Package</th>
          <th scope="col">Package Duration</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Remaining Day</th>
        </tr>
      </thead>
      <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait. </p>  
        </ngx-spinner>  
        
      <tbody *ngFor="let list of activePlan">
        <tr (click)="activatePlan(list.id)" class="active-table-body">
          {{list.id}}
          <th>{{list?.packageName}}</th>
          <td>{{list?.packagePrice}}$</td>
          <td>{{list?.packageType}}</td>
          <td *ngIf="list?.packageStatus===1">Active</td>
          <td *ngIf="list?.packageStatus===0">Deactive</td>
          <td>{{list?.websiteCount}}</td>
          <td>{{ list?.numberofSite - list?.websiteCount }}</td>
          <td>{{list?.numberofSite}}</td>
          <td>{{list?.packageDuration / 30}} month</td>
          <td>{{list?.startDate | date}}</td>
          <td>{{list?.endDate | date}}</td>
          <td *ngIf="list?.daycount< 0">Plan has been expired</td>
          <td *ngIf="list?.daycount>=0">{{list?.daycount}} days</td>
        </tr>
      </tbody>
    </table> -->


    <!-- olderplans -->




    <!-- <h5>All Plan</h5>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Package Name</th>
          <th scope="col">Package Price</th>
          <th scope="col">Package Type</th>
          <th scope="col">Package Status</th>
          <th scope="col">Created Site</th>
          <th scope="col">Remaining Site</th>
          <th scope="col">Site In Package</th>
          <th scope="col">Package Duration</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">Remaining Day</th>
        </tr>
      </thead>
      <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait. </p>  
        </ngx-spinner>  
        
      <tbody *ngFor="let list of list">
        <tr (click)="activatePlan(list.id)">
          {{list.id}}
          <th>{{list?.packageName}}</th>
          <td>{{list?.packagePrice}}$</td>
          <td>{{list?.packageType}}</td>
          <td *ngIf="list?.packageStatus===1">Active</td>
          <td *ngIf="list?.packageStatus===0">Deactive</td>
          <td>{{list?.websiteCount}}</td>
          <td>{{ list?.numberofSite - list?.websiteCount }}</td>
          <td>{{list?.numberofSite}}</td>
          <td>{{list?.packageDuration / 30}} month</td>
          <td>{{list?.startDate | date}}</td>
          <td>{{list?.endDate | date}}</td>
          <td *ngIf="list?.daycount< 0">Plan has been expired</td>
          <td *ngIf="list?.daycount>=0">{{list?.daycount}} days</td>
        </tr>
      </tbody>
    </table> -->
  </div>
</div>
<!-- <mat-drawer-container class="pt-3 pb-5" autosize>
  <mat-drawer #drawer class="user-sidenav" mode="side">
    <app-user-sidenav></app-user-sidenav>
  </mat-drawer>
  

</mat-drawer-container> -->

<!--Footer-->
<app-footer></app-footer>