<footer class="pt-5">
    <div class="container" style="max-width:840px">

        <div class="headingsection text-center">
            <h2 class="text-white">Let’s Create a website<br>with CLICKimize®</h2>
            <div class="footerbtn mt-4"><a routerLink='/UserTemplates' class="btn button-lg btn-secondary">Try it Now</a></div>
        </div>

        <div class="footer-middle">
            <div class="row">
                <div class="col-12 col-md-6 pr-lg-5">
                    <div class="footer-logo mb-3">
                        <img src="{{settingList?.siteLogo}} " class=" img-fluid " />
                    </div>
                    <p>Create a website with CLICKimize® that converts more clicks into leads for your business. Try our landing page website builder- no coding required.</p>
                    <div class="d-flex ">
                        <div class="mr-3 footer_img " >
                            Powered by
                            <img  class="image_logo_footer" src="../assets/images/duda.svg">
                        </div>
                    </div>
                </div>
    
                <div class="col-12 col-md-6">
                    <div class="">
                        <h3>Newsletter Signup</h3>
                        <p class="text-uppercase">Sign Up For Our Newsletter And Get The Latest News.</p>
                        <div class="form-group " class="form" [formGroup]="subscribeForm" (ngSubmit)="onSubmit()">
                            <input class="form-control" type="email" placeholder="Enter Email" formControlName="email">
                            <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                Email is required
                            </span>
                            <br>
                        </div>
                        <button type="submit" class="btn btn-secondary px-5" (click)="onSubmit()">Submit</button>
                    </div>
                </div>
    
                <!-- <div class="col-12 col-md-3 mt-4 mt-md-0 ">
                    <div class="footermenu d-md-flex ">
                        <div class="ml-auto ">
                            <h3>Menu</h3>
                            <ul class="list-unstyled ">
                                <li><a href="/">Home</a></li>
                                <li><a href="/planPricing">Try it Now</a></li>
                                <li><a href="/blog ">Blog</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="d-sm-flex justify-content-between pb-5">
            <div class="d-flex socialconnect">
                <a href="{{settingList?.fbLink}} "> <i class="fab fa-instagram icons"></i></a>
                <a href="{{settingList?.twitterLink}} "> <i class="fab fa-twitter icons"></i></a>
                <a href="{{settingList?.fbLink}} "> <i class="fab fa-facebook-f icons"></i></a>
                <a href="{{settingList?.googlePlus}} "> <i class="fab fa-google-plus-g"></i></a>
                <a href="{{settingList?.linkedinLink}} "><i class="fab fa-linkedin"></i></a>
            </div>
            <div class="ml-auto text-white" style="font-size: 12px;">
                {{settingList?.footerCopyRight}}. All Rights Reserved<br>
                <a href="" routerLink='/terms-of-service' style="color: white;"> Privacy Policy</a> I
                <a href="" routerLink='/privacy-policy'  style="color: white;">Terms & Conditions </a>
             
            </div>
        </div>

    </div>
    <!-- <div class="primary-bg-color py-2 mt-md-5 ">
        <div class="container-fluid">
            <p class="text-white text-center mb-0"> {{settingList?.footerCopyRight}} is a registered trademark of
                Clickimize, LLC. All product names, logos, brands, trademarks and registered trademarks are property of
                their respective owners
            </p>
        </div>

        <div class="copyright "></div>


    </div> -->
</footer>