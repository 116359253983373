<section class="user-admin-sections">
    <app-header></app-header>
</section>
<div class="admin-full-panel">
    <div class="container">
        <div aria-label="breadcrumb" class="">
            <ol class="breadcrumb mb-0 pl-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">My Profile</li>
            </ol>
        </div>
    </div>
    
    <div class="form my__profile mt-4" [formGroup]="ProfileForm" (ngSubmit)="onSubmit()">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group" >
                        <label  style="margin: auto;"class="hoverable" for="fileInput">
                            <img  src="{{baseurl}}profile/{{userRecord?.profileImg}}"  onerror="this.src='../../assets/images/img_avatar.png'">
                            <div class="hover-text">
                                Choose file
                                <h4 class="success-message" *ngIf="url"> Uploaded Successfully </h4>
                            </div>
                            <div class="background"></div>
                        </label>
                        <br />
                        <input id="fileInput" type="file" (change)="selectFile($event)" >
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card border-0 shadow__cust">
                        <div class="card-body">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="Enter First Name"
                                    [readonly]="editMode==2" formControlName="firstName" />
                                <span class="text-danger"
                                    *ngIf="(f.firstName.touched || submitted) && f.firstName.errors?.required ">
                                    First Name is required
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Enter Last Name"
                                    formControlName="lastName" />
                                <span class="text-danger"
                                    *ngIf="(f.lastName.touched || submitted) && f.lastName.errors?.required ">
                                    Last Name is required
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Login Id</label>
                                <input type="text" class="form-control"  readonly
                                    formControlName="email" />
                                <span class="text-danger"
                                    *ngIf="(f.email.touched || submitted) && f.email.errors?.required ">
                                    email required
                                </span>
                            </div>
                            <div class=" rounded-button " style="margin: auto;">
                                <button style="margin: auto; display: block;" type="submit" (click)="onSubmit()" class="btn btn-primary">Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">

    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>
    
</mat-drawer-container> -->

<!--Footer-->
<app-footer></app-footer>