export const environment = {
  production: true,
  baseUrl:'https://api.clickimize.com/',
  dudaUrl :"https://api.duda.co/api/",firebase: {
    apiKey: "AIzaSyBhnCqrDzhhYOQ9OZaBkA4nF8liEvBwOoA",
    authDomain: "clickimize.firebaseapp.com",
    projectId: "clickimize",
    storageBucket: "clickimize.appspot.com",
    messagingSenderId: "201402235345",
    appId: "1:201402235345:web:bda0a11abb3729fcb2c8f0",
    measurementId: "G-ZYRXECNE94"
},
stripeKey:'pk_live_oqlO7xFs8GNdplYN8CrnHDbA'
};
