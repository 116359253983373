<link href='https://fonts.googleapis.com/css?family=Anton|Passion+One|PT+Sans+Caption' rel='stylesheet' type='text/css'>

<section class="user-admin-sections">
    <app-header></app-header>
</section>
<mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">


    <div class="error">
        <div class="container-floud">
            <div class="col-xs-12 ground-color text-center">
                <div class="container-error-404">
                    <div class="clip">
                        <div class="shadow"><span class="digit thirdDigit">4</span></div>
                    </div>
                    <div class="clip">
                        <div class="shadow"><span class="digit secondDigit">0</span></div>
                    </div>
                    <div class="clip">
                        <div class="shadow"><span class="digit firstDigit">4</span></div>
                    </div>
                    <div class="msg">OH!<span class="triangle"></span></div>
                </div>
                <h2 class="h1">Sorry! Page not found</h2>
                <button style="color: white;" type="submit" class="btn btn-warning"><a routerLink="/"> <i class="fas fa-arrow-left"
                            aria-hidden="true"> Back! </i></a></button>
            </div>
        </div>
    </div>

</mat-drawer-container>
<app-footer></app-footer>