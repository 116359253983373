import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-plan-pricing',
  templateUrl: './plan-pricing.component.html',
  styleUrls: ['./plan-pricing.component.css'],
})
export class PlanPricingComponent implements OnInit {
  stripePlans: any = []
  settingList: any;
  successMessage: any;
  packageList: Package[] = [];
  id: any;
  features: any;
  loginStatus: any;
  tokenResponse: any;
  user: any;
  userCurrentPackage: any
  constructor(
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private spinnerService: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginStatus = localStorage.getItem('status');
    this.user = localStorage.getItem('user')
    this.getStripeplans()
    this.getPackageList();
    this.getSettings();
    this.getQueryParams()
  }
  getSettings() {
    this.apiService.getSiteSetting().subscribe((data: any) => {
      this.settingList = data?.siteData;
    });
  }
  // currencySign
  getPackageList() {
    this.spinnerService.show();
    setTimeout(() => {
      this.apiService.getAllPackage().subscribe((data: any) => {
        console.log(data)
        let dt = data.package;
        dt.forEach((element: any) => {
          console.log(element.packageDetail);
          let feature: any = element;
          let pack: Package = {
            id: element.id,
            packageDetail: JSON.parse(feature.packageDetail),
            packageName: element.packageName,
            packagePrice: element.packagePrice,
            stripePriceId: element.stripe_price_id,
            trialPeriod: element.trial_period,
            packageDuration: element.packageDuration,
          };
          this.packageList.push(pack);
        });
        this.spinnerService.hide();
      });
    }, 300);
  }
  onSelect(item: any) {

    const selectedPlan = this.stripePlans.find((plan: any) => item.stripePriceId == plan.id);

    this.apiService.purchagePackageDetails.next(item.packageDetail)

    this.user = this.apiService.getUser();
    if (this.user?.id > 0) {
      // localStorage.setItem('pkgId', id);
      // this.router.navigateByUrl('/purchasePlan');
      this.router.navigate(
        ['/purchasePlan'],
        { queryParams: { pkgId: item.id, pkgAmt: item.packagePrice, amountId: selectedPlan.id, product: selectedPlan.product, packagename: item.packageName } }
      );

    } else {
      this.toaster.error('Please login to continue');
      localStorage.setItem('page', 'plan');
      this.router.navigateByUrl('/signIn');
    }
  }

  async load() {
    const stripePromise = await loadStripe(this.settingList?.stripeApiId);
    const stripe = await stripePromise;

    const result = await stripe?.redirectToCheckout({
      sessionId: this.settingList?.stripeSecretKey, //this.tokenResponse.id,
    });

    if (result?.error) {
    }
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.sitename) {
        localStorage.setItem('publishSiteId', params.sitename)
        this.getUserPackage(params)
      }
    })
  }

  getUserPackage(params: any) {
    const userObj = JSON.parse(this.user)
    // console.log(user.id)
    this.apiService.getUserPlanById(userObj?.id).subscribe(data => {
      console.log(data)
      this.userCurrentPackage = data
      if (data?.message) {
        return
      }
      if (data[0].daycount != 0) {
        if (params && (data[0].numberofSite > data[0].websiteCount)) {
          this.apiService.publishWebsite(params.sitename).subscribe(data => {
            console.log(data)
            if (data?.message === "websitepublished") {
              this.updatePublishedWebsiteCount(params.sitename)
              localStorage.removeItem('publishSiteId')
              this.toaster.success("Congratulations ! Your website has been pulished")
              this.router.navigateByUrl('userDashboard')
            }
          })
        }
        else {
          this.toaster.warning("You have reached your website publish limit, please purchase a plan")
        }
      } else {
        this.toaster.error("You don't have a valid plan or your plan has expired. Please purchase a plan.")
      }

    })
  }

  updatePublishedWebsiteCount(siteID: any) {
    this.apiService.updatePublishedWebsiteCount(siteID).subscribe((data: any) => {
      console.log(data)
    })
  }

  getStripeplans() {
    this.apiService.getstripePlans().subscribe((data: any) => {
      this.stripePlans = data.data
      console.log(data.data)
    })
  }

}

export interface Package {
  id: number;
  packageDetail: PackageFeature[];
  packagePrice: number;
  packageDuration: string;
  packageName: string;
  stripePriceId: string;
  trialPeriod: string;
}

export interface PackageFeature {
  id: number;
  packageDetail: any;
  status: string;
}
