
<!--Header-->

<header class="fixed-top">
    <nav class="navbar navbar-expand-md navbar-dark">
        <a class="navbar-brand" routerLink="/">
            <!-- <img src="{{settingList?.siteLogo}}" style="max-width: 200px;"> -->
            <img src="../../assets/images/logo.png" style="max-width: 200px;">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="icon-menu icons text-dark"></span>
        </button>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <div class="navbar-overlay ml-auto">
                <div class="mobilenav-bar d-md-flex flex-row-reverse">
                    <button data-toggle="collapse" data-target="#collapsibleNavbar" class="close close-nav"><i
                            class="icon-close icons"></i></button>

                    <!---for Desktop -->

                    <div class="loggeduser" *ngIf="user?.id>0">
                       <a routerLink="/message" class="msg-user"><i class="icon-envelope icons "></i></a>
                      &nbsp; <button mat-button [matMenuTriggerFor]="infoList" class="headericon">
                            <i class="fas fa-bell"></i>
                            {{notificationList?.length}}
                        </button>
                        <mat-menu #infoList="matMenu" xPosition="before">
                            <div class="headredropdown">
                                <div class="usertxt-list border-bottom px-3 py-2"
                                    *ngFor="let notify of notificationList">
                                    <a (click)="onSelect(notify.type,notify.tblUserId)">
                                        <div class="usertext-content pl-0" (click)="updateNotifiaction(notify.tblUserId)">
                                            <h6 style="font-size: 15px;">{{notify.title}}</h6>
                                            <p style="font-size: 10px;">{{notify.content}}</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="text-center"><a routerLink="/notification" class="btn btn-link">View All..</a></div>
                            </div>
                        </mat-menu>
                        <button mat-button [matMenuTriggerFor]="menu">
                            <i class="icon-user icons"></i> <span>{{user?.email}}</span>
                            <i class="icon-arrow-down icons"></i>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item routerLink="/userDashboard"> My Dashboard</button>
                            <button mat-menu-item routerLink="/account">Account</button>
                            <button mat-menu-item routerLink="/planpricing">All Plans </button>
                            <button mat-menu-item routerLink="/myplan">My Plan</button>
                            <button mat-menu-item routerLink="/mypayment">Payment History</button>
                            <button mat-menu-item routerLink="/changepassword">Change Password</button>
                            <button mat-menu-item (click)="logout()">Logout</button>
                        </mat-menu>
                    </div>
                    <!--End-->

                    <!---for Mobile -->
                    <div class="mobile-usernav" *ngIf="user?.id">
                        <div class="user-logged">
                            <i class="icon-user icons"></i> <span>{{user?.email}}</span>
                        </div>
                        <ul class="list-unstyled">
                            <li><a routerLink="/account">Account</a></li>
                            <li><a routerLink="/myplan">My Plan </a></li>
                            <li><a routerLink="/mypayment">Payment History</a></li>
                            <li><a routerLink="/changepassword">Change Password</a></li>
                        </ul>
                        <div class="logout-mobile" (click)="logout()"><i class="fa fa-power-off"></i></div>
                    </div>
                    <!--End-->

                    <ul class="navbar-nav">
                        <li class="nav-item pr-md-3">
                            <a class="call-header" tel="18887082409"><span><img src="../../assets/images/call-icon.svg"/></span> 1-888-708-2409</a>
                        </li>
                        <li class="nav-item pr-md-3">
                            <!-- <a class="nav-link" routerLink='/planPricing' >Try it Now</a> -->
                            <a class="btn btn-outline-primary" routerLink='/UserTemplates' >Try it Now</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" routerLink="/blog">Blog</a>
                        </li> -->
                        <li class="nav-item" *ngIf="isLoggedIn==false">
                            <a class="btn btn-secondary btn-block btn-login" routerLink="/signIn">Login</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</header>