<section class="user-admin-sections">
    <app-header></app-header>
</section>
<div class="admin-full-panel">
    <div *ngIf="showForm == 1" class="form my__profile mt-4" [formGroup]="resetpassword" (ngSubmit)="onSubmit()">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="email-for-pass">New Password</label>
                        <input class="form-control" type="password" id="email-for-pass" placeholder="Enter Password" formControlName="newpassword" required>
                        <span class="text-danger" *ngIf="(f.newpassword.touched || submitted) && f.newpassword.errors?.required ">
                        New Password is required
                    </span>
                    </div>
                    <div class="form-group">
                        <label for="email-for-pass">Confirm Password</label>
                        <input class="form-control" type="password" id="email-for-pass" placeholder="Enter Confirm Password" formControlName="confirmpassword" required>
                        <span class="text-danger" *ngIf="(f.confirmpassword.touched || submitted) && f.confirmpassword.errors?.required ">
                        Confirm Password is required
                    </span>
                        <span *ngIf="f.confirmpassword.errors">Passwords must match</span>
                    </div>
    
                </div>
    
            </div>
            <div class="card-footer">
                <button class="btn btn-primary" type="submit" (click)="onSubmit()">Get New Password</button>
                <button class="btn btn-success" type="submit" routerLink="/signIn">Back to Login</button>
            </div>
        </div>
    </div>


    <div class="email__verify" *ngIf="showForm == 2">
        <div class="verify-button text-center">
            <a class="btn btn-primary" routerLink="/signIn"> Go back to login</a>
        </div>
    </div>
</div>

<!--Footer-->
<app-footer></app-footer>