<section class="normal-user-container">
    <app-header></app-header>
</section>

<!--Banner-->

<div class="container home-banner-container">
    
    <div class="banner-text text-center wow fadeInUp">
        <h1>Convert <span><img src="../../assets/images/home/click.svg"/> clicks</span> into leads, create a conversion-driven website.</h1>
        <p>Create a website with CLICKimize® that converts more clicks into leads for your business. Try our landing page website builder- no coding required.</p>
        <div class="btn-banner"><a routerLink="/UserTemplates" class="btn btn-secondary">Start with a Template</a></div>
    </div>
    <img src="/assets/images/home/hero-image.svg" class="img-fluid banner-img" />
</div>

<!---create website section-->

<section class="createawesomewe">
    <div class="container-fluid pr-md-0">
        <div class="headingsection">
            <h2>Create an awesome website in<br>three simple steps.</h2>
            <p>Make something great for your business, yourself, or your website.</p>
        </div>
        <div class="row mr-md-0">
            <div class="order-md-last col-md-8 pr-md-0">
                <img src="../../assets/images/home/create-website.svg" class="img-fluid"/>
            </div>
            <div class="col-md-4">
                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/choose-design.svg"/></div>
                    <div class="steps-txt">
                        <span>STEP 01</span>
                        <h4>Choose a design.</h4>
                        <p>You can make your site stand out with these professional templates.</p>
                    </div>
                </div>

                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/add-content.svg"/></div>
                    <div class="steps-txt">
                        <span>STEP 02</span>
                        <h4>Add your content.</h4>
                        <p>Make it your own by adding your touch. Edit the template, adding words and pictures.</p>
                    </div>
                </div>

                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/publish.svg"/></div>
                    <div class="steps-txt">
                        <span>STEP 03</span>
                        <h4>Publish your site.</h4>
                        <p>Ready to launch? Your website will be published after you hit “publish.”</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- End -->


<!--get statted section-->
<!-- <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8); position: absolute !important;" id="1" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
</ngx-spinner> -->
<section class="get-started-con">
    <div class="container">
        <div id="getstarted-slider" class="carousel slide" data-ride="carousel" data-interval="false">
            <div class="carousel-inner">
             
              <div class="carousel-item active">
                  <!-- {{item.image}} -->
                  <!-- <button (click)="previousImage()" [disabled]="currentImageIndex === 0">Previous</button> -->
                <mat-slider [min]="0" [max]="templatesList?.length - 1" ngDefaultControl  name="currentImageIndex" [(ngModel)]="currentImageIndex"></mat-slider>
                <!-- <button (click)="nextImage()" [disabled]="currentImageIndex === templatesList.length - 1">Next</button> -->
                
                <div class="slideshow-container position-relative">
                    <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" class="spinner-mode" id="2" size="default" type="ball-spin-clockwise">
                        <p style="color: white">Please Wait.</p>
                    </ngx-spinner>
                    <div class="position-relative home-temp" *ngFor="let image of templatesList; let i = index" [hidden]="i !== currentImageIndex">
                       {{image?.siteView}} 
                       <iframe style="width: 100%; height: 500px;border: 0px !important;" [src]="urlSafe"  title="description"></iframe> 
                       
                     <!-- /<img [src]="image.image" [alt]="'Slide ' + (i + 1)"> -->
                      <!-- <div class="tem-box-hover ">
                        <a href="# " class="shortlist-temp shortlisted "><i class="icon-star icons "></i></a>
                        <div class="temp-buttons ">
                            <a href="# " class="btn btn-secondary" (click)="openLg(confirmmodel,image)">Start Building</a>
                            <a  class="btn btn-link " href="https://builder.clickimize.com/preview/{{image.demoSiteUrl}}">Preview</a>
                        </div> -->
                    </div>
                    <!-- </div> -->
                  </div>
             </div>
               <!-- <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div> -->

              <!--  <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div>
              <div class="carousel-item">
                <img src="../../assets/images/slide1.svg" class="w-100"/>
                <div class="slider-txt">Use a website template that is tailored to your industry. Turn your concept into a real online space by personalizing it with your texts and photos.</div>
              </div> -->
            </div>

            <ol class="carousel-indicators">
                <li data-target="#getstarted-slider" data-slide-to="0" [ngClass]="{'active': category == 'Business' ? true : false}" (click)="categoryFilter('Business')">Business website</li>
                <li data-target="#getstarted-slider" data-slide-to="0" [ngClass]="{'active': category == 'Events' ? true : false}" (click)="categoryFilter('Events')">Events</li>
                <li data-target="#getstarted-slider" data-slide-to="1" [ngClass]="{'active': category == 'LandingPage' ? true : false}" (click)="categoryFilter('LandingPage')">Landing Page</li>
                <li data-target="#getstarted-slider" data-slide-to="2" [ngClass]="{'active': category == 'ProfessionalServices' ? true : false}" (click)="categoryFilter('ProfessionalServices')">Professional Service</li>
                <li data-target="#getstarted-slider" data-slide-to="3" [ngClass]="{'active': category == 'Blank' ? true : false}" (click)="categoryFilter('Blank')">Blank</li>
                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'Blog' ? true : false}" (click)="categoryFilter('Blog')">Blog</li>
                <li data-target="#getstarted-slider" data-slide-to="4" [ngClass]="{'active': category == 'LifestyleHealth' ? true : false}" (click)="categoryFilter('LifestyleHealth')">Lifestyle & Health</li>
                <li data-target="#getstarted-slider" data-slide-to="5" [ngClass]="{'active': category == 'OnlineStore' ? true : false}" (click)="categoryFilter('OnlineStore')">Online Store</li>

                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'RestaurantFood' ? true : false}" (click)="categoryFilter('RestaurantFood')">Restaurant Food</li>
                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'Travel' ? true : false}" (click)="categoryFilter('Travel')">Travel</li>
                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'PortfolioResume' ? true : false}" (click)="categoryFilter('PortfolioResume')">Portfolio</li>
                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'CommunityEducation' ? true : false}" (click)="categoryFilter('CommunityEducation')">Community </li>
                <li data-target="#getstarted-slider" data-slide-to="6" [ngClass]="{'active': category == 'OnePager' ? true : false}" (click)="categoryFilter('OnePager')">One Pager</li>
            </ol>
            <!-- <button > -->
            <a class="carousel-control-prev" href="#getstarted-slider" role="button" data-slide="prev" (click)="previousImage()">
              <span class="carousel-control-prev-icon" aria-hidden="true"><img src="../../assets/images/upp-arrow.svg"/></span>
              <span class="sr-only">Previous</span>
            </a>
        <!-- </button> -->
        <!-- <button > -->
            <a class="carousel-control-next" href="#getstarted-slider" role="button" data-slide="next" (click)="nextImage()" >
              <span class="carousel-control-next-icon" aria-hidden="true"><img src="../../assets/images/down-arrow.svg"/></span>
              <span class="sr-only" >Next</span>
            </a>
        <!-- </button> -->
          </div>
          <div class="text-center mt-5"><a routerLink="/UserTemplates" class="btn button-lg btn-outline-primary">View All Category</a></div>
    </div>
</section>

<!-- End -->

<!---create website section-->

<section class="lets-createweb">
    <div class="container-fluid pr-md-0">
        <div class="headingsection">
            <h2>Let’s create an awesome site!</h2>
            <p>Start with a professional template.</p>
        </div>
        <div class="row mr-md-0">
            <div class="order-md-last col-md-7 pr-md-0">
                <img src="../../assets/images/home/lets-create.svg" class="w-100"/>
            </div>
            <div class="col-md-5">
                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/easy-editing.svg"/></div>
                    <div class="steps-txt">
                        <span>Content Your Way.</span>
                        <h4>Easy editing. Flexible design.</h4>
                        <p class="mb-3">The way you want, edit your pictures and add text. There are thousands of free high-quality pictures available for you to use. Pick a font you like.</p>
                        <p>All templates are responsive, so your website will look great on various devices, including computers, tablets, and smartphones. </p>
                    </div>
                </div>

                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/ai-powered.svg"/></div>
                    <div class="steps-txt">
                        <span>Automatic text writer.</span>
                        <h4>AI-Powered text generation.</h4>
                        <p>Using our AI text generator, you may instantly unlock your imagination and generate fresh, unique website material.</p>
                    </div>
                </div>

                <div class="web-steps d-flex">
                    <div class="step-icon"><img src="../../assets/images/home/get-found.svg"/></div>
                    <div class="steps-txt">
                        <span>Search engine optimization.</span>
                        <h4>Get found online.</h4>
                        <p>Your SEO-optimized website is set up to help it rank well in search engines, loading quickly and being easy to use. Tools to improve its ranking on search engines are also simple to use.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- End -->

<section class="website-builder-con">
    <div class="container">
        <div class="headingsection text-center mb-5">
            <h2>It's more than just a website builder.</h2>
            <p>Everything you need for your online presence.</p>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/easy-to-use.svg"/></div>
                    <h4>Easy-To-Use Site<br>Editor to Launch Fast.</h4>
                    <p>A drag & drop site editor that allows you to make edits on the go.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/hosting.svg"/></div>
                    <h4>Hosting, Maintenance<br>and SSL Included.</h4>
                    <p>Let's Encrypt SSL certificates are included, and hosting and updates are processed at blazing speed.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/seo-performance.svg"/></div>
                    <h4>SEO and Performance<br>Built for Greatness.</h4>
                    <p>CLICKimize® is powered by Duda's site architecture which is optimized with Google's lighthouse best practices for speed and performance.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-6 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/edit-device.svg"/></div>
                    <h4>Edit By Device.</h4>
                    <p>Control how your site looks and feels on desktop, tablet, and mobile by customizing every section on any device.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/drag-drop.svg"/></div>
                    <h4>Drag & Drop Editor.</h4>
                    <p>With the CLICKimize® easy-to-use and customizable drag & drop designer, you can save time on development.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/flexible.svg"/></div>
                    <h4>Flexible Menu.</h4>
                    <p>Choose from various menu styles for all devices.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/developer.svg"/></div>
                    <h4>Developer Mode.</h4>
                    <p>Access your responsive website’s HTML and CSS code for full control and flexibility.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/responsive-se.svg"/></div>
                    <h4>Responsive Sections.</h4>
                    <p>Easily build pixel-perfect responsive websites fast, with CSS Grid, Flexbox and Snapping.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 pb-4">
                <div class="box-infoweb">
                    <div class="box-img"><img src="../../assets/images/home/custom-font.svg"/></div>
                    <h4>Custom Font Styles.</h4>
                    <p>Choose beautiful font styles that can be use globally across your responsive website.</p>
                </div>
            </div>

        </div>

        <div class="text-center mt-3"><a routerLink='/UserTemplates' class="btn button-lg btn-outline-primary">Try it Now</a></div>

    </div>
</section>


<section class="online-presen-com">
    <div class="container">
        <div class="headingsection">
            <h2>Your online presence.<br>All in one place.</h2>
            <div class="d-md-flex justify-content-between align-items-start">
                <p class="head-p">Keep track of everything in one place. See your conversions, get  performance recommendations, and quickly access your CLICKimize® dashboard.</p>
                <a routerLink='/UserTemplates' class="btn button-lg btn-secondary">Try it Now</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <img src="../../assets/images/online-presence.svg" class="w-100"/>
            </div>
        </div>
    </div>
</section>

<section class="why-cliclimize">
    <div class="container">
        <div class="headingsection text-center">
            <h2>Why Choose  CLICKimize®.</h2>
            <p>Everything you need to grow your online presence.</p>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 col-lg-3">
                <div class="whyus-box mb-4">
                    <div class="whyusimg"><img src="../../assets/images/home/build-website-icon.svg"/></div>
                    <h4>Build Websites<br>Twice as Fast.</h4>
                    <p>Cut build times in half using our website builder that offers automated workflows & dynamic page creation.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="whyus-box mb-4">
                    <div class="whyusimg"><img src="../../assets/images/home/drive-conversion-icon.svg"/></div>
                    <h4>Drive Conversions<br>with Speed.</h4>
                    <p>Deliver top ranking websites that lead the industry in Core Web Vitals & PageSpeed. Turn websites into conversion engines, backed by speed & SEO.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="whyus-box mb-4">
                    <div class="whyusimg"><img src="../../assets/images/home/security-stability.svg"/></div>
                    <h4>Provide Unmatched<br>Security & Stability.</h4>
                    <p>Shrink maintenance costs and never worry about site security again. Build unbreakable websites with 99.9% uptime.</p>
                </div>
            </div>

            <div class="col-md-6 col-lg-3">
                <div class="whyus-box mb-4">
                    <div class="whyusimg"><img src="../../assets/images/home/design-capebilities.svg"/></div>
                    <h4>Explore Infinite<br>Design Capabilities.</h4>
                    <p>Achieve pixel-perfect design with full editing capabilities or create websites instantly with drag & drop tools and a host of beautiful templates.</p>
                </div>
            </div>

        </div>

        <div class="text-center mt-3"><a routerLink='/UserTemplates' class="btn button-lg btn-secondary">Try it Now</a></div>
    </div>
</section>
<ng-template #confirmmodel let-modal>

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Awesome Website </h4>
        <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
            <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
        </button>
    </div>
    <div class="form-group" style="margin: auto; display: block;">
        {{siteName}}
    </div>
    <div class="temp-image" *ngIf="iconPath && iconPath !== ''">
        <img [src]="iconPath" >
    </div>

    <div class="modal-body" [formGroup]="userForm" (ngSubmit)="startBuildSite()">
        <div class="form-group">
            <label for="websiteName">Website Name<span style="color: red;">*</span>:</label>
            <input type="text" name="websiteName" formControlName="websiteName" placeholder="Enter new website name."
                class="form-control">
            <span class="text-danger" *ngIf="(submitted) && f.websiteName.errors?.required">
                New Website Name is Required
            </span>
        </div>
        <div class="form-group">


            <label for="webSite">Have a website already?<span style="color: red;">*</span>:</label>
            <br>
            <select (change)="showHideTextBox()"  formControlName="webSite" name="webSite" id="webSite"
                class="form-control">
                <option value="">-- Select --</option>
                <option value="no">No</option>
                <option value="yes"> Yes </option>
            </select>
            <span class="text-danger" *ngIf="(submitted) && f.webSite.errors?.required">
                Website is Required
            </span>
        </div>
        <div class="form-group" *ngIf="showContent">
            <label for="siteUrl">Website<span style="color: red;">*</span>:</label>
            <input type="text" name="siteUrl" formControlName="siteUrl" placeholder="Enter your current website URL."
                class="form-control">
            <span class="text-danger" *ngIf="(submitted) && f.siteUrl.errors?.required">
                Site Url Name is Required
            </span>
        </div>
    </div>
    <div class="modal-footer rounded-button">
        <button type="button" class="btn btn-light" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn  btnPrimary" (click)="startBuildSite()">Submit</button>
    </div>
</ng-template>
<!--Footer-->
<app-footer></app-footer>