import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialAuthService } from 

"angularx-social-login";;
import { Toast, ToastrService } from 'ngx-toastr';
import { MustMatch } from '../helpers/mustMatchValidtor';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  user: any;
  ProfileForm: FormGroup;
  submitted = false;
  selectedFiles?: FileList;
  currentFile?: File;
  loggedIn: boolean = false;
  userRecord:any;
  editMode=0;
  url="";
  baseurl = environment.baseUrl
  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private socialAuthService: SocialAuthService, private toastr: ToastrService) {
    this.ProfileForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(2)]],
      lastName: ["", [Validators.required, Validators.minLength(2)]],
      email: ["", [Validators.required, Validators.minLength(2)]],

    })
  }
  ngOnInit(): void {

    this.user = this.apiService.getUser();
    this.myProfile();
  }
  get f() {
    return this.ProfileForm.controls;
  }
  myProfile() {
    this.apiService.getProfile(this.user.id).subscribe(data => {
      this.userRecord=data.user;
      // console.log(this.userRecord)
      this.ProfileForm.patchValue({
        firstName: "" + this.userRecord.firstName,
        lastName: "" + this.userRecord.lastName,
        email: "" + this.userRecord.email,
        profileImg: "" +this.userRecord.profileImg,
      })
    });
  }
  onSubmit() {
    this.apiService.updateUser(this.user.id, {
      firstName: this.ProfileForm.value.firstName,
      lastName: this.ProfileForm.value.lastName,
      email: this.ProfileForm.value.email,
    }).subscribe((event: any) => {
      this.user = this.apiService.getUser();
      if (event.status == 1) {
        this.myProfile();
        this.toastr.success('Profile updated successfully');
      }
      else if (event.status == 0) {
        this.toastr.error('Cannot update profile');
      }
      else if (event.status == 5) {
        this.toastr.error('Unable to update');
      }

    })
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload();
  }
  upload(): void {
   
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        this.currentFile = file;
        this.apiService.updateProfileImage(this.user.id, this.currentFile).subscribe((event: any) => {
          if(event.status === 200 && event.body){
            this.toastr.success("Image uploaded successfully.")
          }
          this.myProfile();
        },
          (err: any) => {

            this.currentFile = undefined;
          });
      }

      this.selectedFiles = undefined;
    }
  }

}
