import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgwWowService } from 'ngx-wow';
import { ApiService } from '../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
/*import * as $ from 'jquery';*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  contactFrom: any;
  subscribeForm: any;
  templatesList: any = [];
  firstSlideArrLany: any = [];
  category: any = 'Business';
  submitted = false;
  userForm!: FormGroup;
  showContent: boolean = false;
  urlSafe!: SafeResourceUrl;
  constructor(private spinnerService: NgxSpinnerService, private sanitizer: DomSanitizer, private router: Router, private modalService: NgbModal, private toaster: ToastrService,
    private toastr: ToastrService, private wowService: NgwWowService, private apiService: ApiService, private formBuilder: FormBuilder,) {
    this.contactFrom = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required]],

      mobileNumber: ['', [Validators.required]],
      message: ["", [Validators.required]],
    });
    this.userForm = this.formBuilder.group({
      webSite: ["", [Validators.required]],
      siteUrl: [""],
      websiteName: ["", [Validators.required]]
    })
    this.wowService.init();
  }
  url: string = "www.example.com";
  ngOnInit(): void {
    this.categoryFilter("Business")

  }
  get f() {
    return this.contactFrom.controls;
  }
  showHideTextBox() {
    let value = this.userForm.value.webSite
    // if (value == 'yes') {
    //   this.showContent = true
    // }
    this.showContent = value === "yes" ? true : false
  }
  dismissModal() {
    this.showContent = false;
    this.modalService.dismissAll();
  }
  onSubmit() {
    this.submitted = true;
    if (this.contactFrom.invalid) {
      return;

    }
    if (this.contactFrom.valid) {
      // console.table(this.contactFrom.value);
    }
    this.apiService.createContactUs({
      firstName: this.contactFrom.value.firstName,
      lastName: this.contactFrom.value.lastName,
      email: this.contactFrom.value.email,
      mobileNumber: this.contactFrom.value.mobileNumber,
      message: this.contactFrom.value.message,
    }).subscribe(data => {
      this.toastr.success('Thank you for contacting us');
      this.contactFrom.reset();
      status = data.status

      if (data.status == 1) {
        this.submitted = false;
        this.contactFrom.patchValue({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          message: "",
        });
      } else {

      }
    })
  }
  categoryFilter(category: any) {
    this.category = category;
    this.spinnerService.show();
    this.apiService.getTemplateByCategory(category).subscribe(data => {
      this.templatesList = data.data;
      this.firstSlideArrLany = data.data;
    //  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.templatesList[0].demoSiteUrl)
      let obj =  this.templatesList .find((o:any, i:any) => {
        if (i === this.currentImageIndex) {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://builder.clickimize.com/preview/"+o.demoSiteUrl)
        }
      });

      // this.templatesList.forEach((element: any, index: number) => {
      //   this.templatesList[index].image = element.thumbPath;
      //   console.log(element.demoSiteUrl)
      //   if (index === 0) {
      //     //  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://builder.clickimize.com/preview/" + element.demoSiteUrl)
      //   }


      //   // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://builder.clickimize.com/preview/' + element.demoSiteUrl);
      // });
      setTimeout(() => {
        this.spinnerService.hide();
      }, 4000);
      
      // this.data = true;
    }, err => {
      this.spinnerService.hide();
      // this.data = false;
    })
  }

  currentImageIndex = 0;
  // imgeUrl = 'https://builder.clickimize.com/preview/5e8121f5c04a427f912e06a8bc2c454f?preview=true&languageCode=en&templateId=1211166'
  nextImage() {
    //this.spinnerService.show();
    console.log(this.currentImageIndex)
    if (this.currentImageIndex < this.templatesList.length - 1) {
      this.currentImageIndex++;
    }
    this.templatesList.forEach((element: any, index: number) => {
      // this.templatesList[index].image = element.thumbPath;
      console.log(element.demoSiteUrl)
      if (index === this.currentImageIndex) {
        
        setTimeout(() => {
          this.spinnerService.hide();
        }, 5000);
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://builder.clickimize.com/preview/' + element.demoSiteUrl);
      }

    });
  }

  previousImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
      this.templatesList.forEach((element: any, index: number) => {
        // this.templatesList[index].image = element.thumbPath;
        console.log(element.demoSiteUrl)
        if (index === this.currentImageIndex) {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://builder.clickimize.com/preview/' + element.demoSiteUrl);
        }
      });
    }
  }
  iconPath: any;
  template_id: any;
  siteName: any;
  id: any
  openLg(content: any, data: any) {
    this.id = data.id;
    this.iconPath = data.iconPath;
    this.siteName = data.name;
    this.template_id = data.template_id;
    //if (this.user.id > 0) {
    this.userForm.patchValue({
      siteName: "" + data.name,
      iconPath: "" + data.iconPath,
      tabletIconPath: "" + data.tabletIconPath
    })
    this.modalService.open(content, {
      size: 'lg'
    });
    // } else {
    //   this.toaster.error('Please login');
    //   this.router.navigateByUrl('/signIn');
    // }

  }
  user: any;
  pID = 0;
  webList: any;
  startBuildSite() {
   // this.spinnerService.show();
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }


    // checking if user is login
    if (this.user?.id > 0) {
      // if(this.pID > 0){
      const body = {
        websiteName: this.userForm.value.websiteName,
        packageDetailId: this.pID,
        userId: this.user.id,
        status: 0,
        default_domain_prefix: "sub-domain",
        "template_id": this.template_id,
        "site_data": {
          "site_domain": "",
          "site_business_info": {
            "business_name": this.user.firstName + " " + this.user.lastName,
            "phone_number": this.user.mobileNumber,
            "email": this.user.email,
            "address": this.user.address,
          }
        },
        "site_alternate_domains": {
          "domains": this.userForm.value.siteUrl,
          "is_redirect": true
        },
        "site_seo": {
          "og_image": this.iconPath,
          "title": "Example Title",
          "description": "Example description. Should be around 155 characters long, but can be upto 320."
        }
      }
      this.modalService.dismissAll();

      this.apiService.createWebSite(body).subscribe((data) => {
        console.log(data)
        this.spinnerService.hide();
        this.webList = data.data;

        if (data.status == 1) {
          this.router.navigate(['/userDashboard'])
          this.toaster.success('Website created successfully');
        } else if (data.status == 5) {
          this.toaster.error('Unable to proccess');
        } else if (data.status = 6) {
          this.toaster.error('Reached maximum site limit purchase a new plan');
          // this.router.navigateByUrl('/planPricing');
        }
      })

      // }
    } else {
      this.modalService.dismissAll();
      this.toaster.error('Please login');
      this.router.navigateByUrl('/signIn');
    }


    // if (this.pID > 0) {
    // const body = {
    //   packageDetailId: this.pID,
    //   userId: this.user.id,
    //   status: 0,
    //   default_domain_prefix: "sub-domain",
    //   "template_id": this.template_id,
    //   "site_data": {
    //     "site_domain": "",
    //     "site_business_info": {
    //       "business_name": this.user.firstName + " " + this.user.lastName,
    //       "phone_number": this.user.mobileNumber,
    //       "email": this.user.email,
    //       "address": this.user.address,
    //     }
    //   },
    //   "site_alternate_domains": {
    //     "domains": this.userForm.value.siteUrl,
    //     "is_redirect": true
    //   },
    //   "site_seo": {
    //     "og_image": this.iconPath,
    //     "title": "Example Title",
    //     "description": "Example description. Should be around 155 characters long, but can be upto 320."
    //   }
    // }
    // if (this.user?.id > 0) {
    //   // console.table(body);
    //   this.apiService.createWebSite(body).subscribe((data) => {
    //     this.spinnerService.hide();
    //     this.modalService.dismissAll();
    //     this.webList = data.data;

    //     if (data.status == 1) {
    //       this.router.navigate(['/userDashboard'])
    //       this.toaster.success('Website created successfully');
    //     } else if (data.status == 5) {
    //       this.toaster.error('Unable to proccess');
    //     } else if (data.status = 6) {
    //       this.toaster.error('Reached maximum site limit purchase a new plan');
    //       // this.router.navigateByUrl('/planPricing');
    //     }
    //   })
    // } else {
    //   this.toaster.error('Please login');
    //   this.router.navigateByUrl('/signIn');
    // }
    // }
    // else {
    //   this.modalService.dismissAll();
    //   this.toaster.error('Please purchase plan & select plan');
    //   this.router.navigateByUrl('/planPricing');
    // }
  }

}

