import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';

import { ApiService } from '../services/api.service';
// import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThisReceiver } from '@angular/compiler';

export interface PeriodicElement {
  siteedit: string;
  sitename: string;
  preview: string;
  overview: string;
  edit: string;
  tablemenu: string;
  description: string;
}



@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})

export class UserDashboardComponent implements OnInit {
  templateList: any;
  status: boolean = false;
  user: any;
  webSiteList: any;
  dataSource: any
  form: any = {};
  userForm!: FormGroup;
  submitted = false;
  checked = "1";
  closeResult: string | undefined;
  editMode = 0;
  successmessage: any;
  saveText = "Save Keyword";
  title = "Create Contact Us";
  id: any;
  iconPath: any;
  name: any;
  site_name: any;
  tempalte_id: any;
  permissionList: any;
  edit = false;
  delete = false;
  reset = false;
  overview = false;
  available = 0;
  created = 0;
  pID = 0;
  userCurrentPackage: any;
  clickimizweWebsiteName: any;
  // clickimizeWebSiteList :any
  clickEvent() {
    this.status = !this.status;
  }


  displayedColumns: string[] = ['select', 'sitename', 'siteedit', 'preview', 'overview', 'edit', 'tablemenu', 'description'];
  selection = new SelectionModel<PeriodicElement>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.sitename + 1}`;
  }


  constructor(private spinnerService: NgxSpinnerService, private router: Router, private formBuilder: FormBuilder, private apiservice: ApiService, private toaster: ToastrService, private modalService: NgbModal) {
    this.userForm = this.formBuilder.group({
      siteName: ["", [Validators.required]],
      iconPath: ["", [Validators.required]],
      duplicateSiteName: ["", [Validators.required]]
    })
    this.apiservice.getToken();
    this.apiservice.allpassss().subscribe(sarae => {
      console.log(sarae)
    })
  }

  ngOnInit(): void {

    this.user = this.apiservice.getUser();
    console.log(this.user)
    // this.DudaUser()
    this.apiservice.UpdateTransation(this.user.id).subscribe((res: any) => {
      if (res.status == 1) {

      }
      if (res.status == 4) {

      }
    })
    this.getUserPlan()
    this.getMyWebSiteList();

  }
  get f() {
    return this.userForm.controls;
  }

  // DudaUser(){
  //       this.apiservice.getDudaUser(this.user.email).subscribe((userData:any) => {
  //     console.log(userData)
  //       })
  // }

  getMyWebSiteList() {
    this.spinnerService.show();
    this.apiservice.getMyWebsiteList(this.user.id).subscribe(data => {
      console.log(data)
      if (data.status == 1) {
        // console.log(data.websiteList)
        this.spinnerService.hide();
        this.webSiteList = data.websiteList;
        // this.clickimizeWebSiteList = data.clickimizeWebSiteList
        //  console.log(this.webSiteList)
        this.filterWebsite(data)
        this.checkThumnailImg(this.webSiteList)
        this.permissionList = data.sitePermission.permissions
      }
      else if (data.status == 4) {
        this.spinnerService.hide();
      }
      if (this.permissionList != null) {
        this.permissionList.forEach((element: any) => {
          if (element == "EDIT") {
            this.edit = true;
          }
          if (element == 'RESET') {
            this.reset = true;
          }
          if (element == 'DELETE') {
            this.delete = true;
          }
          if (element = 'STATS_TAB') {
            this.overview = true;
          }
        });
      }
      else {
        this.permissionList = []
      }
      this.dataSource = new MatTableDataSource<Element>(this.webSiteList);
      // console.log(this.dataSource)

    }, err => {

    });

  }

  deleteSite() {
    this.apiservice.deleteWebSite(this.site_name,).subscribe(data => {
      this.getMyWebSiteList();
      if (data.status == 1) {
        this.toaster.success('Website Delete Successfully');
      }
      else if (data.status == 5) {
        this.toaster.error('Unable To Proccess');
      }
      else if (data.status == 0) {
        this.toaster.error('WebSite Not Found ');
      }
      this.modalService.dismissAll();
    })
  }
  resetWebsite() {
    console.log()
    const body = {
      template_id: this.tempalte_id,
      site_data: {
        removeBizInfos: true
      }
    }
    this.apiservice.resetWebSite(this.site_name, body).subscribe(data => {
      if (data.status == 1) {
        this.toaster.success('Website Reset Successfully');
      }
      else if (data.status == 5) {
        this.toaster.error('Unable To Proccess');
      }
      else if (data.status == 0) {
        this.toaster.error('WebSite Not Found');
      }
      this.modalService.dismissAll();
    })
  }
  updateSiteName() {
    this.apiservice.resetWebSite(this.id, {
      siteName: this.userForm.value.siteName
    }).subscribe(data => {
      if (data.status == 1) {
        this.toaster.success('Rename Successfully');
      }
      else if (data.status == 5) {
        this.toaster.error('Unable To Proccess');
      }
      else if (data.status == 0) {
        this.toaster.error('WebSite Not Found');
      }
      this.modalService.dismissAll();

    })
  }
  onSubmit() {
    this.apiservice.resetWebSite(this.user.id, {
      siteName: this.userForm.value.siteName
    }).subscribe(data => {
      if (data.status == 1) {
        this.toaster.success('Website Reset Successfully');
      }
      else if (data.status == 5) {
        this.toaster.error('Unable To Proccess');
      }
      else if (data.status == 0) {
        this.toaster.error('WebSite Not Found ');
      }
    })
  }
  DuplicateWebsite() {
    this.spinnerService.show()
    // console.log(this.userForm.value.duplicateSiteName.length)
    if ((this.userForm.value.duplicateSiteName).length === 0) {
      this.toaster.warning("please provide a unique name for duplicated website.")
      return
    }
    const body = {
      defaultDomain: this.clickimizweWebsiteName,
      siteId: this.site_name,
      templateId: this.tempalte_id,
      userId: this.user.id,
      packageDetailId: this.pID,
      status: 0,
      duplicateSiteName: this.userForm.value.duplicateSiteName
      // website_name: this.clickimizweWebsiteName,
    }
    this.modalService.dismissAll();
    this.apiservice.duplicateWebSite(body).subscribe((data: any) => {
      this.spinnerService.hide()
      if (data.status == 1) {
        this.toaster.success('Successfully create Copy of website');
        location.reload();
      }
      else if (data.status == 5) {
        this.toaster.error('A subsite for this Website already exist');
      }
      else if (data.status == 0) {
        this.toaster.error('WebSite Not Found');
      }

    })



    // this.apiservice.resetWebSite(this.id, {
    //   status: 0,
    // }).subscribe(data => {
    //   if (data.status == 1) {
    //     this.toaster.success('Successfully create Copy of website');
    //   }
    //   else if (data.status == 5) {
    //     this.toaster.error('Unable To Proccess');
    //   }
    //   else if (data.status == 0) {
    //     this.toaster.error('WebSite Not Found');
    //   }
    //   this.modalService.dismissAll();

    // })
  }

  editSite(site_name: any) {
    this.router.navigateByUrl('https://my.duda.co/home/site/' + site_name);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  open(content: any, data: any) {
    console.log(data)
    this.id = data.id;
    this.iconPath = data.iconPath;
    this.site_name = data.site_name;
    this.clickimizweWebsiteName = data.clickimizeWebsiteName
    this.tempalte_id = data.template_id
    this.userForm.patchValue({
      siteName: "" + data.site_name,
      iconpath: "" + data.iconPath,
      tabletIconPath: "" + data.tabletIconPath
    })
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title'
    }).result.then((result) => {
      console.log(result)
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openLg(content: any, data: any) {
    this.id = data.id
    this.iconPath = data.iconPath;
    this.userForm.patchValue({
      siteName: "" + data.name,
      iconpath: "" + data.iconPath,
      tabletIconPath: "" + data.tabletIconPath
    })
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  getUserPlan() {
    this.apiservice.getUserPlanById(this.user?.id).subscribe((planData: any) => {
      // console.log(planData)
      const activePlan = planData.filter((plan: any) => plan.packageStatus === 1)
      console.log(activePlan)
      this.pID = activePlan[0].id

    })
  }

  overviewWebsite(siteID: any, builderScreenRoute: any) {
    // window.open('http://builder.clickimize.com/home/site/' + siteID, "_blank");
    const screenRoute = builderScreenRoute === "edit" ? 'EDITOR' : null
    this.getSOOloginkey(siteID, screenRoute)




    // this.apiservice.getUserPlanById(this.user.id).subscribe((planData:any)=>{
    //   console.log(planData)
    // if(planData?.message){
    //     this.router.navigateByUrl('/planPricing')
    // }else{
    // window.open('http://builder.clickimize.com/home/site/' + siteID, "_blank");
    //this.getSOOloginkey(siteID)
    // }
    // })
    // console.log(this.user)
    //this.getUserPlan(siteID,site_default_Name)
    // this.dudaFirstTimeLoginPage()
    // console.log("overOview", siteID)

    // href="https://builder.clickimize.com/home/dashboard/overview/{{element.site_name}}"
    //this.createDudaUser()

    //  this.apiservice.authBuilder().subscribe(data=>{
    //   console.log(data)
    //  })
    //   window.open('http://builder.clickimize.com/home/site/02c6d6a9', "_blank");

  }


  // createDudaUser(){
  //   const userData= {
  //       account_type: 'CUSTOMER',
  //       first_name: 'shanu',
  //       last_name: 'vish',
  //       lang: "en",
  //       account_name: 'devUser', // REQUIRED ,UNIQUE

  //       email: 'sanuvish11@gmail.com',
  //       company_name: 'alina'
  //   }
  //   this.apiservice.createDudaUser(userData).subscribe((response: any)=> {
  //     console.log(response)
  //   })
  // }



  // getUserPlan(siteID: any,site_default_Name:string) {


  // // const email = "sanuvish11@gmail.com"
  //     // const uniqueuserName = "devuser"
  //     this.apiservice.getDudaUser(this.user.email).subscribe((userData:any) => {
  //       console.log(userData)
  //       if(userData.result?.message){
  //         this.toaster.error("cannot find user on the builder")
  //       }else{
  //         console.log(site_default_Name)
  //         const defaultSiteName = site_default_Name.split('.')[0]
  //        console.log(defaultSiteName)
  //       // this.getSOOloginkey(siteID)
  //       }
  //     })
  //   }

  getSOOloginkey(siteName: any, route: any) {
    // url: 'https://api.duda.co/api/accounts/sso/account_name/link?site_name=werwerrewrrrr&target=wrwerw',

    const body = {
      accountName: this.user.email,
      site_name: siteName,
      route: route
    }
    this.apiservice.SooLogin(body).subscribe((data: any) => {
      console.log(data)
      window.open(data.url, "_blank");
    })
  }

  //   this.apiservice.getUserPlanById(this.user.id).subscribe(data => {
  //     console.log(data)
  //     console.log(siteID)
  //     data.forEach((pkg: any) => {
  //       if (pkg.numberofSite > pkg.websiteCount) {
  //         this.pID = pkg.id;
  //         if(this.pID>0){
  //           // window.location.href = 'https://builder.clickimize.com/home/dashboard/overview/'+ siteID ;
  //           window.open('https://builder.clickimize.com/home/dashboard/overview/'+ siteID , "_blank");
  //         }
  //       }
  //       this.available = this.available + pkg.numberofSite;
  //       this.created = this.created + pkg.websiteCount;
  //     }); 
  //   })
  // }

  //   verifyUser() {
  //     const email= "dev@gm.com"
  //     const userData= [{
  //       account_type: 'CUSTOMER',
  //       lang: "en",
  //       account_name: email, // REQUIRED ,UNIQUE 
  //       email: email,
  //   },{
  //     status : 1
  //   }
  // ]

  //     this.apiservice
  //       .updateByEmail(email,userData
  //       //    {

  //       //   status: 1,
  //       // }
  //       )
  //       .subscribe((data: any) => {
  // console.log(data)
  //         // this.apiservice
  //         //   .updateToken(this.user.token, {
  //         //     status: 1,
  //         //   })
  //         //   .subscribe((data) => {});

  //         // if (event.status == 1) {
  //         //   this.toaster.success('verification successful, please login');
  //         //   this.router.navigateByUrl('/signIn');
  //         // } else if (event.status == 0) {
  //         //   this.toaster.error('Please regenerate verification link');
  //         // } else if (event.status == 5) {
  //         //   this.toaster.error('Unable to process');
  //         // }
  //       });
  //   }


  dudaCreatePassordPage() {
    console.log(this.user)
    this.apiservice.dudaGeneratePassword(this.user.email).subscribe((data: any) => {
      console.log(data)
      window.open(data.welcome_url, "_blank");


    })
  }

  checkThumnailImg(websiteList: any) {
    this.apiservice.getTemplateList().subscribe((allTemp: any) => {
      const allTemplates = allTemp.data
      websiteList.forEach((element: any) => {
        // console.log(element.template_id)
        if (!element.thumbnail_url) {
          const template = allTemplates.find((temp: any) => temp.template_id === element.template_id)
          // console.log(template)
          element.thumbnail_url = template.thumbPath
          // this.webSiteList.push(this.webSiteList[0].thumbnail_url =  template.thumbPath )
        }
        // console.log(this.webSiteList)
      });
    })

  }


  createWesite() {
    // if(this.webSiteList?.length === 0 || this.webSiteList ===  undefined){
    //   // window.location.reload();
    //   this.dudaCreatePassordPage()
    // }
    this.router.navigateByUrl('/UserTemplates')
  }


  // updatePublishedWebsiteCount(siteID:any){
  //   this.apiservice.updatePublishedWebsiteCount(siteID).subscribe((data:any)=>{
  //     console.log(data)
  //   })
  // }

  publishWebsite(siteID: any) {
    this.spinnerService.show()
    // const userObj =JSON.parse(this.user)
    // console.log(user.id)
    this.apiservice.getUserPlanById(this.user?.id).subscribe(data => {
      console.log(data)
      this.spinnerService.hide()
      this.userCurrentPackage = data
      if (data?.message) {
        console.log(data.message)
        this.toaster.warning("You have no active plan. Purchase a plan to continue publishing your website.")
        this.router.navigateByUrl('/planpricing')
      }
      if (data[0].daycount != 0) {
        if ((data[0].numberofSite > data[0].publishedSite)) {

          this.apiservice.publishWebsite(siteID).subscribe(data => {
            this.getMyWebSiteList();
            if (data?.message === "websitepublished") {
              this.updatePublishedWebsiteCount(siteID)
              localStorage.removeItem('publishSiteId')
              this.toaster.success("Congratulations ! Your website has been pulished")
              location.reload();
              // this.router.navigateByUrl('userDashboard')
            }
          })
        }
        else {
          this.toaster.warning("You have reached your website publish limit, please purchase a plan")
        }
      } else {
        this.toaster.error("You don't have a valid plan or your plan has expired. Please purchase a plan.")
      }

    })
  }

  updatePublishedWebsiteCount(siteID: any) {
    this.apiservice.updatePublishedWebsiteCount(siteID).subscribe((data: any) => {
      console.log(data)
    })
  }

  openPublishedwebsite(siteAddress: string, publishStatus: string) {
    if (publishStatus === "PUBLISHED") {
      // window.location.href= siteAddress;
      window.open(siteAddress, '_blank');
    } else {
      this.toaster.warning("Please publish your website")
    }
  }


  filterWebsite(allWebsiteData: any) {
    allWebsiteData.websiteList.forEach((element: any, i: number) => {
      const clickimizeWebsiteName = allWebsiteData.clickimizeWebSiteList.find((website: any) => website.site_name === element.site_name);
      this.webSiteList[i].clickimizeWebsiteName = clickimizeWebsiteName?.website_name != null ? clickimizeWebsiteName.website_name : `Website${element.site_name}`
    });
  }

}

