
<div class="sidemainnav">
    <div class="searchteampcat">
        <i class="icon-magnifier icons"></i>
    <input type="text" placeholder="Search" class="form-control">
</div>

<ul class="list-unstyled mb-0">
    <li><a href="#" class="active">All Templates</a></li>
    <li><a  routerLink="/myfavorite"><i class="icon-star icons"></i> Favorites</a></li>
    <li><a href="#"><i class="icon-basket icons"></i> Online Store</a></li>
</ul>

</div>

  <mat-accordion multi="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>CATEGORIES</mat-panel-title>
      </mat-expansion-panel-header>
    <div class="cat-sabmenu">
      <ul class="list-unstyled mb-0">
        <li><a href="#">Business</a></li>
        <li><a href="#">Landing Page</a></li>
        <li><a href="#">Professional Services</a></li>
        <li><a href="#">Blank</a></li>
        <li><a href="#">Events</a></li>
        <li><a href="#">Lifestyle & Health</a></li>
        <li><a href="#">Travel</a></li>
    </ul>
    </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Colors</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="color-filters">
        <ul class="list-inline mb-0">
            <li class="list-inline-item"><a href="#" class="whit-color"></a></li>
            <li class="list-inline-item"><a href="#" class="green-color"></a></li>
            <li class="list-inline-item"><a href="#" class="blue-color"></a></li>
            <li class="list-inline-item"><a href="#" class="orange-color"></a></li>
            <li class="list-inline-item"><a href="#" class="black-light-color"></a></li>
            <li class="list-inline-item"><a href="#" class="green-light-color"></a></li>
            <li class="list-inline-item"><a href="#" class="pink-color"></a></li>
            <li class="list-inline-item"><a href="#" class="yellow-color"></a></li>
            <li class="list-inline-item"><a href="#" class="blue-dark-color"></a></li>
            <li class="list-inline-item"><a href="#" class="black-color"></a></li>
            <li class="list-inline-item"><a href="#" class="blue-light-color"></a></li>
            <li class="list-inline-item"><a href="#" class="gray-color"></a></li>
        </ul>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>SORT BY</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="cat-sabmenu">
            <ul class="list-unstyled mb-0">
              <li><a href="#">Featured</a></li>
              <li><a href="#">A to Z</a></li>
              <li><a href="#">Z to A</a></li>
          </ul>
          </div>
      </mat-expansion-panel>
  </mat-accordion>