<section class="user-admin-sections">
    <app-header></app-header>
</section>
<!-- <div class="createEditorPasswordStrip" *ngIf="webSiteList?.length === 0 || webSiteList ===  undefined">
    <label><b>Hello {{user.firstName}}</b>, Set a new or existing password for your builder too before creating your first website. 
        <p>*Builder password can be different from Clickimize password.</p>
        <div>
            <a (click)="dudaCreatePassordPage()" class="btn btn-primary">Create Editor Password
            </a>
        </div>
</label>
</div> -->
<mat-drawer-container class="pb-5" autosize>
    <!--<mat-drawer #drawer class="user-sidenav" mode="side" [opened]="true">-->
    <!-- <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>
    <button class="toggle-sidenav" type="button" mat-button (click)="drawer.toggle()">
        <i class="icons" (click)="clickEvent()" [ngClass]="status ? 'icon-arrow-left' : 'icon-arrow-right'"></i>
    </button> -->
    <div class="admin-right-panel">
        <div class="container pb-4">
            <div class="inner_heading mt-3">
                <div class="d-md-flex">
                    <div class="py-2">
                        <h2>Site Dashboard</h2>
                    </div>
                    <div class="py-2">
                        <div class="searchteampcat ml-md-3">
                            <i class="icon-magnifier icons"></i>
                            <input type="text" (keyup)="applyFilter($event)" placeholder="Search" #input
                                class="form-control">
                        </div>
                    </div>
                    <div class="ml-auto py-2">
                        <a (click)="createWesite()" class="btn btn-primary whiteFocus"> Create Awesome Website</a>
                    </div>
                </div>
            </div>
            <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
                <p style="color: white">Please Wait. </p>
            </ngx-spinner>
            <div class="templates-dashboard mt-4">
                <div class="d-flex flex-wrap temp-container">
                    <!--Table-->
                    <div class="mat-elevation-z8 w-100 sitetable">
                        <table mat-table [dataSource]="dataSource" class="w-100">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- <mat-checkbox> -->
                                        <!-- (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()" -->
                                    <!-- </mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <!-- <mat-checkbox> -->
                                        <!-- (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" -->
                                    <!-- </mat-checkbox> -->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sitename">
                                <th mat-header-cell *matHeaderCellDef class="text-left"> SITES </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="siteViewContent">
                                        <div class="siteviewimg">
                                            <img src="{{element.thumbnail_url}}" class="img-fluid">
                                        </div>
                                        <div class="siteviewtxt">
                                            <div class="">
                                                <h5> {{element.clickimizeWebsiteName}} </h5>
                                                <button *ngIf="element.publish_status=='NOT_PUBLISHED_YET'"
                                                    class="btn btn-primary">Not Published</button>
                                                <button *ngIf="element.publish_status=='PUBLISHED'"
                                                    class="btn btn-primary publish-button">
                                                    <i class="fa fa-check" style="color:green"  aria-hidden="true"></i>
                                                    Published</button>
                                            </div> 
                                            <span class="website-domain"><a (click)="openPublishedwebsite(element.canonical_url, element.publish_status)">{{element.site_default_domain}}</a>
                                                
                    
                                            </span>
                                            <!-- <p class="mb-0">URL Not Available (Site Not Yet Published)</p> -->
                                            <small>Created: {{element.creation_date | date}}</small>&nbsp;
                                            <small>Last Published: {{element.last_published_date | date}}</small>
                                            <div class="tags-con">
                                                <span class="badge badge-secondary">{{element.site_name}} <i
                                                        class="fa fa-times"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="siteedit">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- https://builder.clickimize.com/home/site/e83b35d4 -->

                                    <a *ngIf='element.publish_status === "NOT_PUBLISHED_YET"' (click)="publishWebsite(element.site_name)"><i
                                            class="icon-pencil icons"></i> Publish</a>
                                            <a *ngIf='element.publish_status === "PUBLISHED"' (click)="overviewWebsite(element.site_name,element.site_default_domain)"><i
                                                class="icon-refresh icons"></i> Republish</a>
                                </td>
                            </ng-container>
                            <!-- <label (click)="updatePublishedWebsiteCount(element.site_name)"> </label> -->
                            <ng-container matColumnDef="preview">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element" >
                                    <a target="blank" href="{{element.preview_site_url}}"><i
                                            class="icon-control-play icons"></i> Preview</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- https://builder.clickimize.com/home/dashboard/overview/{{element.site_name}} -->

                                    <a target="blank" (click)="overviewWebsite(element.site_name,'edit')"><i
                                            class="icon-cursor icons"></i> Edit</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="overview">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- https://builder.clickimize.com/home/dashboard/overview/{{element.site_name}} -->

                                    <a target="blank" (click)="overviewWebsite(element.site_name,'overview')"><i
                                            class="icon-cursor icons"></i> Overview</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="tablemenu">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button [matMenuTriggerFor]="beforeMenu" class="tablemanu">
                                        <i class="icon-options-vertical icons"></i>
                                    </button>
                                    <mat-menu #beforeMenu="matMenu" xPosition="before" class="tablemanucon">
                                        <!-- <button mat-menu-item (click)="open(renamemodal,element)">
                                            <span>Rename</span>
                                        </button> -->
                                        <button mat-menu-item (click)="open(duplicatemodel,element)">
                                            <span>Duplicate</span>
                                        </button>
                                        <button mat-menu-item routerLink="/mypayment">
                                            <span>Payment Info</span>
                                        </button>
                                        <button mat-menu-item (click)="openLg(largemodal,element)">
                                            <span>Save as Template</span>
                                        </button>
                                        <button *ngIf="reset==true" mat-menu-item (click)="open(resetmodal,element)">
                                            <span>Reset</span>
                                        </button>
                                        <button *ngIf="delete==true" mat-menu-item (click)="open(mymodal,element)">
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef> SUBSCRIPTION </th>
                                <td mat-cell *matCellDef="let element">
                                    <i class="icon-info" routerLink="/myplan"></i>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="selection.toggle(row)">
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                    <!--End-->
                </div>
            </div>
        </div>
    </div>

    <ng-template #largemodal let-modal>
        <div class="modal-header">


            <h4 class="modal-title" id="modal-basic-title">Save as Template</h4>


            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="modal-body" class="form" [formGroup]="userForm" style="margin-left: 4em; margin-right: 4em;">
            <h4>Save this site as a template so you can use it over and over again.</h4>
            <div class="temp-image" *ngIf="iconPath && iconPath !== ''">
                <img [src]="iconPath" [alt]="name">
            </div>

            <div class="form-group">
                <label for="siteName">Template name</label>
                <input type="text" name="siteName" formControlName="siteName" [hidden]="editMode==1"
                    placeholder="Give your temlate a name" class="form-control" />
                <span class="text-danger" *ngIf="(f.siteName.touched || submitted) && f.siteName.errors?.required">
                    Template Name
                </span>
            </div>
        </div>

        <div class="modal-footer rounded-button " style="margin: auto; ">
            <button type="button" class="btn btn-outline-secondary" [hidden]="editMode==2"
                (click)="modal.close('Save click')">Close</button>

            <button type="submit" (click)="onSubmit()" class="btn btn-primary" [hidden]="editMode==2">
                save & customize</button>
        </div>
    </ng-template>
    <!-- small model? -->
    <ng-template #mymodal let-modal>
        <div>
            <!-- <h4 class="modal-title" id="modal-basic-title">Basic Modal</h4> -->
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="modal-body">
            Are you sure you want to delete the site?
        </div>
        <div class="modal-footer rounded-button">
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Cancel</button>
            <button type="button" class="btn btn-primary  sitebutton" (click)="deleteSite()">Delete Site</button>
        </div>
    </ng-template>

    <ng-template #resetmodal let-modal>
        <div>
            <!-- <h4 class="modal-title" id="modal-basic-title">Basic Modal</h4> -->
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="modal-body">
            <span>Resetting this site will delete all site content, data and settings, enabling you to start from
                scratch. Site subscription, domain settings, stats, and permissions settings are saved. <br> If you want
                to reset the site while keeping the content, go to the Design panel and click Reset Site &gt; Keep Site
                Data.</span>
        </div>
        <div class="modal-footer rounded-button">
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Cancel</button>
            <button type="button" class="btn btn-primary  sitebutton" (click)="resetWebsite()">Complete Reset </button>
        </div>
    </ng-template>
    <ng-template #renamemodal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Rename Site</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="modal-body" [formGroup]="userForm" (ngSubmit)="updateSiteName()">
            <span>Change the site name to make it easier to find on your dashboard.</span>
            <br>
            <div class="form-group">
                <label for="siteName">Site Name</label>
                <input type="text" name="siteName" formControlName="siteName" [readonly]="editMode==2"
                    [hidden]="editMode==1" placeholder="Enter Site Name " class="form-control" />
                <span class="text-danger" *ngIf="(submitted) && f.siteName.errors?.required">
                    Site Name is Required
                </span>
            </div>
        </div>
        <div class="modal-footer rounded-button">

            <button type="button" class="btn btn-primary" (click)="updateSiteName()">Rename </button>
            <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
        </div>
    </ng-template>
    <ng-template #duplicatemodel let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Duplicate Site</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="modal-body" [formGroup]="userForm" (ngSubmit)="DuplicateWebsite()">
            <span>Create an exact copy of this site. This new copied site will be unpublished and require a new custom
                domain.</span>
            <br>
            <div class="form-group">
                <label for="duplicateSiteName">Site Name</label>
                <input type="text" name="duplicateSiteName" formControlName="duplicateSiteName" [readonly]="editMode==2"
                    [hidden]="editMode==1" placeholder="Enter a unique Site Name" class="form-control" required/>
                <span class="text-danger" *ngIf="(submitted) && f.duplicateSiteName.errors?.['required']">
                    Site Name is Required
                </span>
            </div>
        </div>
        <div class="modal-footer rounded-button">
            <button type="button" class="btn btn-primary" (click)="DuplicateWebsite()">Copy </button>
            <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
        </div>
    </ng-template>
</mat-drawer-container>

<!--Footer-->
<app-footer></app-footer>