import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
// import { NgxStripeModule } from 'ngx-stripe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgwWowModule } from 'ngx-wow';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PlanPricingComponent } from './plan-pricing/plan-pricing.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { SigninComponent } from './signin/signin.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { UserSidenavComponent } from './user-sidenav/user-sidenav.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { SignupComponent } from './signup/signup.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { MatCardModule } from '@angular/material/card';
import { ShareButtonComponent } from './helpers/share-button/share-button.component';

// import { NgxSpinnerModule } from "ngx-spinner";
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetComponent } from './reset/reset.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyPlanComponent } from './my-plan/my-plan.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { MyFavoriteComponent } from './my-favorite/my-favorite.component';
import { FilterPipe } from './filter-pipe.pipe';
import { authInterceptorProviders } from './services/auth.interceptor';
import { SuccessComponent } from './payment/success/success.component';
import { FailureComponent } from './payment/failure/failure.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { SortPipe } from './sort.pipe';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MessageComponent } from './message/message.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
// import { authInterceptorProviders } from './services/auth.interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { AngularFireModule } from '@angular/fire/compat';

import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

import { SafePipe } from './services/safe';
import { CookieService } from 'ngx-cookie-service';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PlanPricingComponent,
    UserInfoComponent,
    SigninComponent,
    UserPanelComponent,
    UserSidenavComponent,
    SignupComponent,
    UserDashboardComponent,
    BlogComponent,
    BlogDetailComponent,
    ShareButtonComponent,
    ForgetPasswordComponent, MyPaymentComponent,SafePipe,
    ResetComponent, MyProfileComponent, MyPlanComponent, PurchasePlanComponent, ChangePasswordComponent, SanitizeHtmlPipe, MyFavoriteComponent, FilterPipe, SuccessComponent, FailureComponent, AccountVerificationComponent, SortPipe, ErrorPageComponent, MessageComponent, NotificationListComponent, TermConditionComponent, PrivacyPolicyComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    BrowserModule,
    MatMenuModule,
    MatSidenavModule,
    BsDatepickerModule.forRoot(),
    MatRadioModule,
    NgbModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,
    AngularEditorModule,
    ToastrModule.forRoot(),
    MatCardModule,
    NgxSpinnerModule,
    SocialLoginModule, 
    AngularFireModule.initializeApp(environment.firebase),
    NgxStripeModule.forRoot(environment.stripeKey),// AngularFireAuthModule,
  //  AngularFireModule.initializeApp(environment.firebase),
  ],
 
  providers:
    [ AuthService,{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     '201402235345-4j9fcous21ni96upe727ab3g6pp91f66.apps.googleusercontent.com'
          //   )
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1268901283962261')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },CookieService
   ],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
