<div class="login-container d-flex">
    <div class="my-auto w-100">
        <div class="row mx-0">
            <div class="col-sm-12 px-0">
                <div class="container-fluid px-0">
                    <div class="login-maxW mx-auto">
                        <div class="row mx-0">
                            <div class="col-md-12 col-lg-5 px-0">
                                <div class="sign-logo">
                                    <a routerLink="/"><img src="/assets/images/logo.png" /></a>
                                </div>
                                <div class="login-content">
                                    <div class="login-center">
                                        <h2 class="text-center">Login</h2>

                                            <!-- <div class="signupwithGmail">
                                                <a class="btn btn-block" (click)="signInWithGoogle()">
                                                    <img src="../../assets/images/google.svg"/> Sign in with Google
                                                </a>
                                            </div> -->

                                            <!-- <div class="or-sep"><span>OR</span></div> -->

                                            <form class="form" [formGroup]="loginForm" (ngSubmit)="submitLogin()">
                                                <div class="form-group">
                                                    <label>Email Address*</label>
                                                    <!-- <i class="icon-user icons"></i> -->
                                                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" />
                                                    <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                                    Email id is required
                                                </span>
                                                    <!--<span class="error-type">Please enter Correct user name</span>-->
                                                </div>
                                                <div class="form-group">
                                                    <label>Password*</label>
                                                    <!-- <i class="icon-lock icons"></i> -->
                                                    <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                                                    <span class="text-danger" *ngIf="(f.password.touched || submitted) && f.password.errors?.required">
                                                    Password is required
                                            </span>
                                                    <!--<span class="error-type">Password is wrong</span>-->
                                                </div>
                                                <div class="d-flex">
                                                    <div class="remember">
                                                        <mat-checkbox class="re-checkbox" [checked]="rememberCheck" (change)="rememberPass($event)">Remember</mat-checkbox>
                                                    </div>
                                                    <div class="forgotpass ml-auto"><a routerLink="/forgetpassword">Forgot your password?</a></div>
                                                </div>
                                                <button class="btn btn-block btn-primary">Log in</button>
                                            </form>
                                            <div class="signup-t">Don't have an account? <a routerLink="/signUp">sign up here</a></div>
                                            
                                            <!-- <div class="signupwithFacebook">
                                                <a  class="btn btn-block" (click)="signInWithFacebook()"><i class="fab fa-facebook-f"></i> Log in With Facebook</a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            <!-- <div class="row" *ngIf="socialService.userData as user">
                                <div class="col-md-12">
                                  <div class="media">
                                    <img class="align-self-start mr-5 img-thumbnail rounded-circle" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                                      alt="{{user.displayName}}">
                                    <div class="media-body">
                                      <h1>Hello: <strong>{{(user.displayName) ? user.displayName : 'User'}}</strong></h1>
                                      <p>User ID: <strong>{{user.uid}}</strong></p>
                                      <p>Email: <strong>{{user.email}}</strong></p>
                                      <p>Email Verified: <strong>{{user.emailVerified}}</strong></p>
                                    </div>
                                  </div>
                                </div>
                              </div> -->
                            <div class="col-md-12 col-lg-7 px-0 mobilenone text-center">
                                <div class="h-100 d-flex">
                                    <div class="my-auto w-100">
                                        <img src="/assets/images/login-bg.jpg" class="img-fluid" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>