import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-my-payment',
  templateUrl: './my-payment.component.html',
  styleUrls: ['./my-payment.component.css']
})
export class MyPaymentComponent implements OnInit {
  user: any;
  trasactionList: any
  errMessage: any
  constructor(private apiService: ApiService, private spinnerService: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.getMyTrasactionList();
  }
  getMyTrasactionList() {
    this.spinnerService.show()
    this.user = this.apiService.getUser();
    this.apiService.getMyTrasaction(this.user.id).subscribe(data => {
      if (data.status == 1) {
        this.trasactionList = data.data;
      }
      else if (data.status == 4) {
        this.errMessage = "No transaction"
      }
      // console.log(data)
      this.spinnerService.hide()

    })
  }

}
