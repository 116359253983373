<section class="normal-user-container">
    <app-header></app-header>
</section>

<section class="blog-title">
    <div class="pattern-layer-one"></div>
    <div class="auto-container wow fadeInDown">
        <h2>Our Blog</h2>
        <div aria-label="breadcrumb" class="">
            <ol class="breadcrumb justify-content-center mb-0">
                <li class="breadcrumb-item"><a routerLink="">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Blog</li>
            </ol>
        </div>>
    </div>
</section>
<ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Please Wait. </p>  
    </ngx-spinner>

<section class="blog-container">
    <div class="container">
        <div class="row">
            <p class="text-center" style="width: 100%;"> {{errMessage}} </p>
            <!-- <p class="text-center">Center aligned text on all viewport sizes.</p> -->
            <div class="col-md-4" *ngFor="let blog of blogList">
                <mat-card class="blog-card wow fadeInDown">
                    <div class="blog-image">
                        <a (click)="onSelect(blog?.blogUrl)">
                            <img mat-card-image src="{{blog?.blogImage}}" alt="Image">
                        </a>
                    </div>
                    <mat-card-header>
                        <div mat-card-avatar class="blog-poster-image"></div>
                        <mat-card-title>{{blog?.adminName}}</mat-card-title>
                        <mat-card-subtitle>{{blog?.createdAt | date}}- <i class="icons icon-clock"></i> {{blog?.estimateTime}}.
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <h4><a (click)="onSelect(blog?.blogUrl)">{{blog?.blogTitle}}</a></h4>
                        <p>
                            {{blog?.metaDescription}}
                        </p>
                        <a class="read-more" (click)="onSelect(blog.blogUrl)">Read More<span
                                class="icon-arrow-right icons"></span></a>
                    </mat-card-content>
                    <style>
                        .brown {
                            color: #9b6b6b
                        }
                    </style>
                    <div class="d-flex blog-footer">
                        <div class="likes-blog brown" (click)="likeBlog(blog.id)">
                            <a [ngClass]="{'text-primary':blog.count, 'text-success':!blog.count}"><i
                                    class="icon-like icons "></i>
                                {{blog.count}}</a>
                        </div>
                        <div class="blog-comments ml-auto ">
                            <a routerLink="# "><i class="icon-share icons "></i> </a>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- <div class="col-md-4 ">
                <mat-card class="blog-card wow fadeInDown ">
                    <div class="blog-image ">
                        <a routerLink="/Blog-Detail ">
                            <img mat-card-image src="/assets/images/blog/news-2.jpg " alt="Image ">
                        </a>
                    </div>
                    <mat-card-header>
                        <div mat-card-avatar class="blog-poster-image "></div>
                        <mat-card-title>Admin</mat-card-title>
                        <mat-card-subtitle>Feb 09, 2021 - <i class="icons icon-clock "></i> 1 min.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <h4><a routerLink="/Blog-Detail ">The Shiba Inu is the smallest</a></h4>
                      <p>
                        The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                      </p>
                      <a class="read-more " routerLink="/Blog-Detail ">Read More<span class="icon-arrow-right icons "></span></a>
                    </mat-card-content>
                    <div class="d-flex blog-footer ">
                        <div class="likes-blog ">
                            <a routerLink="# "><i class="icon-like icons "></i> 10 Likes</a>
                        </div>
                        <div class="blog-comments ml-auto ">
                            <a routerLink="# "><i class="icon-share icons "></i> 3 Share</a>
                       </div>
                    </div>
                  </mat-card>
            </div> -->

            <!-- <div class="col-md-4 ">
                <mat-card class="blog-card wow fadeInDown ">
                    <div class="blog-image ">
                        <a routerLink="/Blog-Detail ">
                            <img mat-card-image src="/assets/images/blog/news-3.jpg " alt="Image ">
                        </a>
                    </div>
                    <mat-card-header>
                        <div mat-card-avatar class="blog-poster-image "></div>
                        <mat-card-title>Admin</mat-card-title>
                        <mat-card-subtitle>Feb 09, 2021 - <i class="icons icon-clock "></i> 1 min.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <h4><a routerLink="/Blog-Detail ">The Shiba Inu is the smallest</a></h4>
                        <p>
                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                        </p>
                        <a class="read-more " routerLink="/Blog-Detail ">Read More<span class="icon-arrow-right icons "></span></a>
                    </mat-card-content>
                    <div class="d-flex blog-footer ">
                        <div class="likes-blog ">
                            <a routerLink="# "><i class="icon-like icons "></i> 10 Likes</a>
                        </div>
                        <div class="blog-comments ml-auto ">
                            <a routerLink="# "><i class="icon-share icons "></i> 3 Share</a>
                        </div>
                    </div>
                </mat-card>
            </div> -->

            <!-- <div class="col-md-4 ">
                <mat-card class="blog-card wow fadeInDown ">
                    <div class="blog-image ">
                        <a routerLink="/Blog-Detail ">
                            <img mat-card-image src="/assets/images/blog/news-5.jpg " alt="Image ">
                        </a>
                    </div>
                    <mat-card-header>
                        <div mat-card-avatar class="blog-poster-image "></div>
                        <mat-card-title>Admin</mat-card-title>
                        <mat-card-subtitle>Feb 09, 2021 - <i class="icons icon-clock "></i> 1 min.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <h4><a routerLink="/Blog-Detail ">The Shiba Inu is the smallest</a></h4>
                        <p>
                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                        </p>
                        <a class="read-more " routerLink="/Blog-Detail ">Read More<span class="icon-arrow-right icons "></span></a>
                    </mat-card-content>
                    <div class="d-flex blog-footer ">
                        <div class="likes-blog ">
                            <a routerLink="# "><i class="icon-like icons "></i> 10 Likes</a>
                        </div>
                        <div class="blog-comments ml-auto ">
                            <a routerLink="# "><i class="icon-share icons "></i> 3 Share</a>
                        </div>
                    </div>
                </mat-card>
            </div> -->
            <!-- 
            <div class="col-md-4 ">
                <mat-card class="blog-card wow fadeInDown ">
                    <div class="blog-image ">
                        <a routerLink="/Blog-Detail ">
                            <img mat-card-image src="/assets/images/blog/news-6.jpg " alt="Image ">
                        </a>
                    </div>
                    <mat-card-header>
                        <div mat-card-avatar class="blog-poster-image "></div>
                        <mat-card-title>Admin</mat-card-title>
                        <mat-card-subtitle>Feb 09, 2021 - <i class="icons icon-clock "></i> 1 min.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <h4><a routerLink="/Blog-Detail ">The Shiba Inu is the smallest</a></h4>
                        <p>
                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                        </p>
                        <a class="read-more " routerLink="/Blog-Detail ">Read More<span class="icon-arrow-right icons "></span></a>
                    </mat-card-content>
                    <div class="d-flex blog-footer ">
                        <div class="likes-blog ">
                            <a routerLink="# "><i class="icon-like icons "></i> 10 Likes</a>
                        </div>
                        <div class="blog-comments ml-auto ">
                            <a routerLink="# "><i class="icon-share icons "></i> 3 Share</a>
                        </div>
                    </div>
                </mat-card>
            </div> -->
        </div>
    </div>
</section>

<!--Footer-->
<app-footer></app-footer>