import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from '../helpers/mustMatchValidtor';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  errMessage: any;
  user: any;
  submitted = false;
  usermessageList: any;
  manualSignupForm: FormGroup;
  interValId: any;
  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {
    this.manualSignupForm = this.formBuilder.group({
      message: ["", [Validators.required]],
    })

  }

  ngOnInit(): void {
    this.user = this.apiService.getUser();
    this.messageList();
    this.interValId = setInterval(() => {
      this.messageList();
    }, 1500);
  }
  get f() {
    return this.manualSignupForm.controls;
  }

  //send meessage to admin
  sendMessage() {
    this.messageList();
    this.submitted = true;
    if (this.manualSignupForm.invalid) {
      return;
    }
    this.apiService.createMessage({
      message: this.manualSignupForm.value.message,
      tblUserId: this.user.id,
      to: 'user',
      from: 'admin'
    }).subscribe(data => {
      this.messageList();
      this.manualSignupForm.reset();
      this.manualSignupForm.patchValue({
        message: ""
      })
    })
  }
  messageList() {
    this.spinner.show();
    this.apiService.userMessageList(this.user.id).subscribe(data => {
      // console.log(data);
     
      this.spinner.hide();
      if (data.status == 1) {
        this.errMessage = ''
        this.usermessageList = data.data;
      }
      else if (data.status == 4) {
        this.errMessage = 'There are no conversation available';
      }
    })

  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  ngAfterViewChecked() {
     this.scrollToBottom();
  }
  ngOnDestroy() {
    // console.log('destroy')
    if (this.interValId) {
      clearInterval(this.interValId)
    }

  }

  createMessage(){}
}
