import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from
  "angularx-social-login";;
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import * as auth from 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  user: any;
  userData: any;
  loggedIn = false;
  rememberCheck = false
  constructor(private router: Router, private formBuilder: FormBuilder, private cookieSer: CookieService,
    private apiService: ApiService, private authService: SocialAuthService, private toastr: ToastrService, public socialService: AuthService, public afAuth: AngularFireAuth) {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    this.getSiteToken();
    let remember = this.cookieSer.get('remember');
    console.log(remember)
    if (remember === 'yes') {
      this.rememberCheck = true
      this.loginForm.patchValue({
        email: this.cookieSer.get('username'),
        password: this.cookieSer.get('password')
      })
    }
    this.authService.authState.subscribe((user) => {
      console.log("user", user)
      this.user = user;
      this.loggedIn = (user != null);
      console.log("islogin", this.loggedIn)
    });

    /* Saving user data in localstorage when 
     logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }
  getSiteToken() {
    this.apiService.siteToken().subscribe(data => {
      localStorage.setItem("token", data.accessToken);
      this.apiService.saveToken(data.accessToken);
    })
  }
  submitLogin() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if (this.loginForm.valid) {

    }
    this.login(this.loginForm.value.email, this.loginForm.value.password, 1, 1)
  }

  login(email: string, password: string, type: number, status: any) {

    this.apiService.login({
      email: email,
      password: password,
      userType: type,
      status: status
    }).subscribe(res => {

      this.apiService.saveToken(res.accessToken);
      if (res.status === 1) {
        this.apiService.loginResponse.next('nextLogin')
        this.toastr.success("Success", "Login Successful");
        const data = res.user;
        localStorage.setItem("status", res.status);
        localStorage.setItem("data", data)
        this.apiService.saveUser(data);
        localStorage.setItem("token", data.accessToken);

        let page = localStorage.getItem("page") || "";
        if (page == "plan") {
          this.router.navigate(["/planPricing"], {
            replaceUrl: false
          });
          localStorage.removetItem("page");
        } else {
          this.router.navigate(["/userDashboard"], {
            replaceUrl: false
          });
        }
      } else if (res.status === 3) {
        this.toastr.error('Invalid Password');
      }
      else if (res.status === 4) {
        this.toastr.error('Invalid Username, Please Signup First.');
      }
      else if (res.status === 5) {
        this.toastr.error('Unable to process');
      }
      else if (res.status === 6) {
        this.toastr.error('Please verify your email id');
      }
      else if (res.status === 7) {
        this.toastr.error('User Not Active');
      }
      else {
        this.toastr.error('You have registered successfully, Please signin.');
      }
    });
  }


  signInWithGoogle(): void {
    this.socialService.AuthLogin(new auth.GoogleAuthProvider(), 'signin').then((x: any) => {
      // console.log(x,"108");
      // this.SetUserData(x,"result");
      // console.log(this.socialService.userData,"109");
      // this.login(x.email, "", 2, 1);
    }).catch((err: any) => {
      console.log(err);
    })
  }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
  //     this.login(x.email, "", 2, 1);
  //   }).catch(err => {
  //     console.log(err);
  //   })
  // }

  signInWithFacebook(): void {

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      this.login(x.email, "", 3, 1);
    }).catch(error => { console.log(error); });;
  }

  SetUserData(user: any, type: any) {
    console.log(user, type);
    const userData: any = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    console.log(userData);
  }
  rememberPass(event: any) {

    if (event.checked == true && this.loginForm.valid) {

      this.cookieSer.set('remember', 'yes')
      this.cookieSer.set('username', this.loginForm.value.email)
      this.cookieSer.set('password', this.loginForm.value.password)
    }
    if (event.checked == false) {
      this.cookieSer.delete('remember')
      this.cookieSer.delete('username')
      this.cookieSer.delete('password')
    }

  }
}