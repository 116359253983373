import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  subscribeForm: any;
  submitted = false;
  settingList: any;
  constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder,) {
    this.subscribeForm = this.formBuilder.group({
      email: ["", [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.getSiteToken();

  }
  get f() {
    return this.subscribeForm.controls;
  }
  getSiteToken() {
    this.apiService.siteToken().subscribe((data: any) => {
      localStorage.setItem("token", data.accessToken);
      this.apiService.saveToken(data.accessToken);
    })
  }
  ngAfterViewInit() {
    this.getFooterSettings()
  }
  getFooterSettings() {
    this.apiService.getSiteSetting().subscribe(data => {
      this.settingList = data.siteData;
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.subscribeForm.invalid) {
      return;
    }
    if (this.subscribeForm.valid) {
      // console.table(this.subscribeForm.value);
    } this.apiService.createSubscribe({
      email: this.subscribeForm.value.email,
      status: 1
    }).subscribe(data => {
      this.toastr.success('Thank you for subscribing clickimize newsletter');
      this.subscribeForm.reset();
      status = data.status
      if (data.status == 1) {
        this.submitted = false;
        this.subscribeForm.patchValue({
          email: "",
        });
      } else {
    
      }
    })
  }
}
