import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  blogID = 0;
  id: any;
  public href: string = "";
  blogUrl: any;
  blogList: any;
  blogData: any;
  user: any;
  type: string = ""; //'facebook' | 'twitter';
  shareUrl: string = "";
  navUrl: string = "";
  keywords: any;
  userLike = [];
  like: any;

  constructor(private spinnerService: NgxSpinnerService, private route: ActivatedRoute, private apiService: ApiService, private router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.blogUrl = window.location.href;
    this.href = this.router.url;
    this.user = this.apiService.getUser();
    this.id = this.route.snapshot.paramMap.get('id');
    this.apiService.getBlogByUrl(this.id).subscribe(data => {
      this.blogData = data.blogdata;
      this.blogID = data.blogdata.id;
      this.keywords = JSON.parse(this.blogData.metaTag.split(","));
    });
    this.getBlogList();
    setTimeout(() => this.userLikeList(), 3000);
  }
  createNavigationUrl(type: string, url: string) {
    let searchParams = new URLSearchParams();
    switch (type) {
      case 'facebook':
        searchParams.set('u', this.blogUrl);
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + searchParams;
        break;
      case 'twitter':
        searchParams.set('url', this.blogUrl);
        this.navUrl = 'https://twitter.com/share?' + searchParams;
        break;
      case 'pinterest':
        searchParams.set('url', this.blogUrl);
        this.navUrl = 'http://pinterest.com/pin/create/link/?url=' + searchParams;
        break;
    }
    this.share();
  }

  public share() {
    return window.open(this.navUrl, "_blank");
  }
  getBlogList() {
    this.spinnerService.show();
    this.apiService.getAllBlogList().subscribe(data => {
      this.blogList = data;
      this.spinnerService.hide();
    })
  }
  onSelect(blogUrl: any) {
    this.spinnerService.show();
    this.apiService.getBlogByUrl(blogUrl).subscribe(data => {
      this.blogData = data.blogdata;
      if (this.blogData.length != 0) {
        this.router.navigate(['/blog-detail/' + blogUrl]);
        this.spinnerService.hide();
      }
      else {
        this.router.navigateByUrl('/');
      }
    })
  }
  onSubmit(id: any) {
    if (this.user.id >= 0) {
      const blogid = id;
      this.apiService.likeBlog({
        tblUserId: this.user.id,
        tblBlogId: blogid,
      }).subscribe(data => {

        this.like = true;
      });
    }
    else {
      this.router.navigateByUrl('/signIn');
    }

  }

  userLikeList() {

    this.apiService.getUserLikeBlogById(this.user.id).subscribe(data => {

      this.userLike = data;
      let dt = this.blogID;
      let exist = [] = data.filter(() => data.includes(this.blogID));

      let filtered = data.filter((el: any) => el === this.blogID)


      if (exist.length > 0) {
        this.like = true;
      
      }
    })
  }
  getMetaTag(tag: any) {
    localStorage.setItem('tag', tag);
    this.router.navigateByUrl('/blog');
  }
}
