<section class="user-admin-sections">
  <app-header></app-header>
</section>
<mat-drawer-container class="pb-5" autosize style="min-height: 100vh;">

  <mat-drawer #drawer class="user-sidenav" mode="side">
    <app-user-sidenav></app-user-sidenav>
  </mat-drawer>
<div class="admin-full-panel">
  <div aria-label="breadcrumb" class="">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
      <li class="breadcrumb-item active" aria-current="page">Payment</li>
    </ol>
  </div>

  <div class="container-lg mt-5 mypayment" style="max-width:1280px;">
    <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please Wait. </p>
          </ngx-spinner>

          <span class="text-danger" style="text-align: center;">
            <p>{{errMessage}}</p>
          </span>

          <div class="row">
            <div class="col-md-6 mb-3" *ngFor="let list of trasactionList;">
              <div class="card shadow border-0">
                <div class="card-body">
                  <div class="transaction-de">
                    <span>Transaction Id</span>
                    <p>{{list?.transactionId}}</p>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="transaction-de">
                        <span>Package Price</span>
                        <p>${{list?.amount}}</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="transaction-de">
                        <span>Transaction Status</span>
                        <p *ngIf="list?.status==0" style="color: #ff0000;">Failed</p>
                        <p *ngIf="list?.status==1" style="color: #81A125;">Success</p>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="transaction-de">
                        <span>Purchase Date</span>
                        <p>{{list?.paymenDate | date}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    <!-- <div class="card shadow border-0">
      <div class="card-body">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">Transaction Id</th>
              <th scope="col">Package Price</th>
              <th scope="col">Transaction Status</th>
              <th scope="col">Purchase Date</th>
    
            </tr>
          </thead>
          <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please Wait. </p>
          </ngx-spinner>

          <span class="text-danger">
            {{errMessage}}
          </span>
    
          <tbody>
            
          
            <tr *ngFor="let list of trasactionList;">
              <td>{{list?.transactionId}}</td>
              <td> ${{list?.amount}}</td>
              <td *ngIf="list?.status==0"> Failed</td>
              <td *ngIf="list?.status==1"> Succeeded</td>
              <td>{{list?.paymenDate | date}}</td>
    
              <td *ngIf="list?.status==0">Deactive</td>
    
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
  </div>
</div>
  
  
</mat-drawer-container>

<!--Footer-->
<app-footer></app-footer>