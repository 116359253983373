import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  user: any;
  form = {};
  forgetPassword: FormGroup;
  submitted = false;
  constructor(private router: Router, private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService) {
    this.forgetPassword = this.formBuilder.group({
      email: ["", [Validators.required]],
    })
  }
  ngOnInit(): void {
  }
  get f() {
    return this.forgetPassword.controls;
  }
  onSubmit() {
    this.apiService.resetPassword({
      email: this.forgetPassword.value.email
    }).subscribe((event: any) => {
      if (event.status == 1) {
        this.router.navigateByUrl('/signIn');
        this.toastr.success('Email with the reset password link has been sent to your email');
      }
      else if (event.status == 0) {
        this.toastr.error('Account not found!');
      }
      else if (event.status == 5) {
        this.toastr.error('Unable to process');
      }

    })
  }



}
