<section class="user-admin-sections">
    <app-header></app-header>
</section>
<mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">
    <!--<mat-drawer #drawer class="user-sidenav" mode="side" [opened]="true">-->
    <mat-drawer #drawer class="user-sidenav" mode="side">
        <!-- <app-user-sidenav></app-user-sidenav> -->

        <div class="sidemainnav">
            <div class="searchteampcat">
                <i class="icon-magnifier icons"></i>
                <input type="text" placeholder="Search" class="form-control">
            </div>

            <!-- <ul class="list-unstyled mb-0">
                <li><a (click)="showTemplates('all')" [class.active]="currentSelection == 'all'">All Templates</a></li>
                <li><a (click)="showTemplates('fav')" [class.active]="currentSelection == 'fav'"><i
                            class="icon-star icons"></i> Favorites</a></li>
                <li><a (click)="showTemplates('online')" [class.active]="currentSelection == 'online'"><i
                            class="icon-basket icons"></i> Online Store</a></li>
            </ul> -->

        </div>

        <mat-accordion multi="true">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>CATEGORIES</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="cat-sabmenu">
                    <ul class="list-unstyled mb-0">
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Landing Page</a></li>
                        <li><a href="#">Professional Services</a></li>
                        <li><a href="#">Blank</a></li>
                        <li><a href="#">Events</a></li>
                        <li><a href="#">Lifestyle & Health</a></li>
                        <li><a href="#">Travel</a></li>
                    </ul>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>Colors</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="color-filters">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <a href="#" class="whit-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="green-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="blue-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="orange-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="black-light-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="green-light-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="pink-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="yellow-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="blue-dark-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="black-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="blue-light-color"></a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="gray-color"></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>SORT BY</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="cat-sabmenu">
                    <ul class="list-unstyled mb-0">
                        <li><a href="#">Featured</a></li>
                        <li><a href="#">A to Z</a></li>
                        <li><a href="#">Z to A</a></li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-drawer>
    <button class="toggle-sidenav" type="button" mat-button (click)="drawer.toggle()">
        <i class="icons"  [ngClass]="status ? 'icon-arrow-left' : 'icon-arrow-right'"></i>
    </button>

    <div class="admin-right-panel">
        <div class="inner_heading">
            <div class="backto">
                <a routerLink="/UserDashboard" class="btn btn-link"><i class="icon-arrow-left icons"></i> Back To
                    Sites</a>
            </div>
            <h1>My Favorites Item</h1>
            <!-- <p>Select your starting point, customize easily, and build a great website.</p> -->
        </div>

        <div class="templates-dashboard mt-5">
            <div class="d-flex flex-wrap temp-container">

                <div class="temp-box-style shadow "
                *ngFor="let item of myFav | filter:searchText; let i = index; ">
                <div *ngIf="item.isNew=='true'" class="new-temp">New</div>
                <div class="tem-box-hover " >
                    <a class="shortlist-temp shortlisted">
                        <a style="text-decoration: none;"
                            [ngClass]="{'text-primary':item.favourite, 'text-success':!item.favourite}"><i
                                class="icon-star"></i>
                        </a>


                    </a>
                    <div class="temp-buttons">
                        <a  class="btn btn-secondary" (click)="startbuildSite(item.template_id)">Start
                            Building</a>
                        <a href="https://www.duda.co/preview?site={{item.demoSiteUrl}}"
                            class="btn btn-link ">Preview</a>
                    </div>
                </div>
                <div class="temp-image "
                    style="background-image:url( {{item.image}}); background-repeat: no-repeat;background-size: contain;background-position: center;">
                    <img src="" alt="Barber Shop " style="display:none ">
                </div>
                <div class="temp-bottom d-flex ">
                    <h4 class="temp-head align-self-center ">{{item.name}}</h4>
                    <div class="temp-view align-self-center ml-auto ">
                        <div class="d-flex ">
                            <a (click)="filterfun(i,item.iconPath)" class="icon-screen-desktop icons "></a>
                            <a (click)="filterfun(i,item.tabletIconPath)"
                                class="icon-screen-tablet icons icons "></a>
                            <a (click)="filterfun(i,item.mobileIconPath)"
                                class="icon-screen-smartphone icons icons "></a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-wrap temp-container " style="display: none !important; ">

        <!-------Template box 1--------->

        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp shortlisted "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/JP.png " alt="Barber Shop "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Barber Shop</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------End Template box--------->

        <!-------Template box 2--------->

        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/AP.png " alt="Vacation "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Vacation</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 3--------->

        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/HT.png " alt="Products "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Products</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 4--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/ZE.png " alt="Blank Parallax - RTL "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Blank Parallax - RTL</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 5--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/Bz.png " alt="Blank Parallax - RTL "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Blank Parallax - RTL</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 6--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/dT.png " alt="Hobby Shop "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Hobby Shop</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 7--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/Wm.png " alt="Recruitment Agency "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Recruitment Agency</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 8--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/GT.png " alt="Personal Trainer "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Personal Trainer</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------End--------->

    </div>


</mat-drawer-container>

<!--Footer-->
<app-footer></app-footer>