<section class="user-admin-sections">
    <app-header></app-header>
</section>
<div class="admin-full-panel">
    <div class="container">
        <div aria-label="breadcrumb pl-0" class="">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/userDashboard">User Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Message</li>
            </ol>
        </div>
        <div class="row my__profile mt-4" [formGroup]="manualSignupForm"
            (ngSubmit)="createMessage()">
            <div class="col-md-12">
                <div class="card shadow-sm rounded mb-4">
                    <div class="card-header">
                        <h6 style="margin: auto;padding: auto;display: block;text-align: center;font-size:30px">Message</h6>
                        <div style="font-weight: bold;">
    
                            <img style="width: 70px;height: auto; object-fit: cover; object-position: center center; border: 50%;
                                border-radius: 50%;" src={{user.profileImg}} 
                                 onerror="this.src='../../assets/images/img_avatar.png'" >
                            {{user.firstName}}
                        </div>
                    </div>
                    <div class="card-body p-3 scollrbar" #scrollMe>
                        <label>Conversation List</label>
                        <div class="text-danger">
                            {{errMessage}}
                        </div>
                        <div class="form-group" style="height: auto;width: auto;" *ngFor="let msg of usermessageList">
    
                            <hr>
                            <!-- <br> -->
                         
    
                            <div class="chat-body clearfix" >
                                <div [ngClass]="msg.from === 'admin' ? 'right' : 'left'">
                                    <p>
                                        {{msg.message}}
                                    </p>
                                    <p style="font-size: 10px;">
                                        {{msg?.createdAt | date:'shortTime'}}
                                    </p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <button class="btn btn-block btn-primary"  (keydown.enter)="createMessage()" (click)="createMessage()" type="submit">send</button> -->
            </div>
            <div class="input-group card-body p-3">
                <input id="btn-input" type="text" class="form-control input-sm"
                    placeholder="Continue discussion..." formControlName="message" (keydown.enter)="sendMessage()">
                <span class="input-group-btn">
                    <button class="btn btn-sm btn btn-block btn-primary" 
                        id="btn-chat" (click)="sendMessage()">
                        send
    
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
<!-- <mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">
    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>
</mat-drawer-container> -->

<!--Footer-->
<app-footer></app-footer>