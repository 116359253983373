<section class="user-admin-sections">
    <app-header></app-header>
</section>
<mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">
    <!-- <mat-drawer #drawer class="user-sidenav" mode="side" [opened]="true"> -->
    <mat-drawer #drawer class="user-sidenav" mode="side" opened="true">
        <!-- <app-user-sidenav></app-user-sidenav> -->
        <div class="sidemainnav">
            <div class="d-flex">
                <div class="searchteampcat">
                    <i class="icon-magnifier icons"></i>
                    <input type="text" placeholder="Search" [(ngModel)]="searchText" autocomplete="off"
                        class="form-control">
                </div>
                <button class="toggle-sidenav ml-auto position-static" id="sidenav-arrow" type="button" mat-button
                    (click)="drawer.toggle()">
                    <i class="icons" (click)="clickEvent()"
                        [ngClass]="status ? 'icon-arrow-left' : 'icon-arrow-right'"></i>
                </button>
            </div>
            <ul class="list-unstyled mb-0">
                <li><a (click)="getTemplteList()" [class.active]="currentSelection == 'all'">All Templates</a></li>
                <li><a (click)="getFilterTemplate('fav')" [class.active]="currentSelection == 'fav'"><i
                            class="icon-star icons"></i> Favorites</a></li>
                <li><a (click)="categoryFilter('online')" [class.active]="currentSelection == 'online'"><i
                            class="icon-basket icons"></i> Online Store</a></li>
            </ul>

        </div>

        <mat-accordion multi="true">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>CATEGORIES</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="cat-sabmenu">
                    <ul class="list-unstyled mb-0">
                        <li class="pointer"><a (click)="categoryFilter('Business')"
                                [class.active]="currentSelection == 'Business'">Business website</a></li>

                        <li class="pointer"><a (click)="categoryFilter('Events')"
                                [class.active]="currentSelection == 'Events'">Events</a></li>
                                
                        <li class="pointer"><a (click)="categoryFilter('LandingPage')"
                                [class.active]="currentSelection == 'LandingPage'">Landing Page</a></li>
                        <li class="pointer"><a (click)="categoryFilter('ProfessionalServices')"
                                [class.active]="currentSelection == 'ProfessionalServices'">Professional
                                Services</a></li>
                        <li class="pointer"><a (click)="categoryFilter('Blank')"
                                [class.active]="currentSelection == 'Blank'">Blank</a></li>
                        <li class="pointer"><a (click)="categoryFilter('Blog')"
                                [class.active]="currentSelection == 'Blog'">Blog</a></li>
                        <li class="pointer"><a (click)="categoryFilter('LifestyleHealth')"
                                [class.active]="currentSelection == 'LifestyleHealth'">Lifestyle</a></li>
                        <li class="pointer"><a (click)="categoryFilter('OnlineStore')"
                                [class.active]="currentSelection == 'OnlineStore'">Online Store</a></li>
                        <li class="pointer"><a (click)="categoryFilter('RestaurantFood')"
                                [class.active]="currentSelection == 'RestaurantFood'">Restaurant Food</a></li>
                        <!-- <li class="pointer"><a (click)="categoryFilter('ProfessionalServices')"
                                [class.active]="currentSelection == 'ProfessionalServices'">Professional Service</a></li> -->
                        <li class="pointer"><a (click)="categoryFilter('Travel')"
                                [class.active]="currentSelection == 'Travel'">Travel </a></li>
                        <li class="pointer"><a (click)="categoryFilter('PortfolioResume')"
                                [class.active]="currentSelection == 'PortfolioResume'">Portfolio </a></li>
                        <li class="pointer"><a (click)="categoryFilter('CommunityEducation')"
                                [class.active]="currentSelection == 'CommunityEducation '">Community </a></li>
                        <li class="pointer"><a (click)="categoryFilter('OnePager')"
                                [class.active]="currentSelection == 'OnePager'">One Pager</a></li>
                    </ul>
                </div>
            </mat-expansion-panel>
            <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
                <p style="color: white">Please Wait. </p>
            </ngx-spinner>
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>Colors</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="color-filters">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('white')" [class.active1]="currentSelection == 'white'"
                                class="whit-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('green')" [class.active1]="currentSelection == 'green'"
                                class="green-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('blue')" [class.active1]="currentSelection == 'blue'"
                                class="blue-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('orange')" [class.active1]="currentSelection == 'orange'"
                                class="orange-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('black-light')"
                                [class.active1]="currentSelection == 'black-light'" class="black-light-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('light-green')"
                                [class.active1]="currentSelection == 'light-green'" class="green-light-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('pink')" [class.active1]="currentSelection == 'pink'"
                                class="pink-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('yellow')" [class.active1]="currentSelection == 'yellow'"
                                class="yellow-color"></a>
                        </li>
                        <!-- <li class="list-inline-item pointer">
                            <a (click)="templateFilter('blue-dark')" class="blue-dark-color"></a>
                        </li> -->
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('black')" [class.active1]="currentSelection == 'black'"
                                class="black-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('blue-light')" [class.active1]="currentSelection == 'blue-light'"
                                class="blue-light-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('gray')" [class.active1]="currentSelection == 'gray'"
                                class="gray-color"></a>
                        </li>
                        <li class="list-inline-item pointer">
                            <a (click)="templateFilter('purple')" [class.active1]="currentSelection == 'purple'"
                                class="purple-color"></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>SORT BY</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="cat-sabmenu">
                    <ul class="list-unstyled mb-0">
                        <li><a href="#">Featured</a></li>
                        
                        <li><a (click)="assendingOrder('ASC')">A to Z</a></li>
                        <li><a (click)="assendingOrder('DESC')">Z to A</a></li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-drawer>
    <button class="toggle-sidenav" type="button" mat-button (click)="drawer.toggle()">
        <i class="icons" (click)="clickEvent()" [ngClass]="status ? 'icon-arrow-left' : 'icon-arrow-right'"></i>
    </button>

    <div class="admin-right-panel">
        <div class="inner_heading">
            <div class="backto" *ngIf="isLoggedIn==true">
                <a routerLink="/userDashboard" class="btn btn-link"><i class="icon-arrow-left icons"></i> Back To
                    Sites</a>
            </div>
            <h1>Choose a Template</h1>
            <p>Select your starting point, customize easily, and build a great website.</p>

        </div>
        <ngx-spinner style="background-color:rgba(51, 51, 51, 0.8);" size="default" type="ball-spin-clockwise">
            <p style="color: white">Please Wait. </p>
        </ngx-spinner>
        <div *ngIf="data==false" style="display: block; margin: auto;text-align: center; font-size:large;"
            class="text-danger">{{errorMessage}}</div>
        <div class="templates-dashboard mt-5">
            <div *ngIf="data" class="row temp-container">

                <!-- <div class="item  col-xs-4 col-lg-4" style="display:none !important ">
                    <div class="thumbnail ">
                        <img class="group list-group-image " src="{{item.thumbnail_url}} " alt=" " style="width: 358px; height: 228px; " />
                        <div class="caption ">
                            <h4 class="group inner list-group-item-heading ">
                                {{ item.template_name }}
                            </h4>

                            <div class="row ">
                                <div class="col-xs-12 col-md-6 ">
                                    <a href="{{item.preview_url}} " target="_blank " class="btn btn-success ">Preview</a>
                                    <a class="btn btn-success " data-toggle="modal ">Start</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <style>
                    .btn-primary {
                        background-color: blue;
                        width: 40px;
                        height: 40px;
                    }

                    .btn-danger {
                        background-color: #dc3545;
                        width: 40px;
                        height: 40px;
                    }
                </style>
                <div class="col-md-4" *ngFor="let item of templatesList | filter:searchText ; let i = index; ">
                    <div class="temp-box-style shadow">
                        
                        <span *ngIf="item.favourite" style="text-decoration: none;" class="item__added"><i
                            class="icon-star"></i>
                    </span>
                        <div *ngIf="item.isNew=='true'" class="new-temp">New</div>
                        <div class="tem-box-hover ">
                            <a *ngIf="!item.favourite" class="shortlist-temp shortlisted" (click)="templateAddToFavi(item.template_id)">
                                <a style="text-decoration: none;"
                                    [ngClass]="{'text-primary':item.favourite, 'text-success':!item.favourite}"><i
                                        class="icon-star"></i>
                                </a>
                            </a>
                            <div class="temp-buttons">
                                <a class="btn btn-secondary" (click)="openLg(confirmmodel,item)">Start
                                    Building</a>

                                <a target="blank" href="https://builder.clickimize.com/preview/{{item.demoSiteUrl}}"
                                    class="btn btn-link ">Preview</a>
                            </div>
                        </div>
                        <div class="temp-image " [ngStyle]="{'background-image': 'url(' + item.image+ ')'}"
                            style="background-repeat: no-repeat;background-size: contain;background-position: center;">
                            <img src="" alt="Barber Shop " style="display:none ">
                        </div>
                        <div class="temp-bottom d-flex ">
                            <h4 class="temp-head align-self-center ">{{item.name}}</h4>
                            <div class="temp-view align-self-center ml-auto ">
                                <div class="d-flex ">
                                    <a (click)="filterFun(i,item.iconPath)" class="icon-screen-desktop icons "></a>
                                    <a (click)="filterFun(i,item.tabletIconPath)"
                                        class="icon-screen-tablet icons icons "></a>
                                    <a (click)="filterFun(i,item.mobileIconPath)"
                                        class="icon-screen-smartphone icons icons "></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #confirmmodel let-modal>

        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Create Awesome Website </h4>
            <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
                <mat-icon aria-hidden="false" aria-label="close icon">close</mat-icon>
            </button>
        </div>
        <div class="form-group" style="margin: auto; display: block;">
            {{siteName}}
        </div>
        <div class="temp-image" *ngIf="iconPath && iconPath !== ''">
            <img [src]="iconPath">
        </div>

        <div class="modal-body" [formGroup]="userForm" (ngSubmit)="startBuildSite()">
            <div class="form-group">
                <label for="websiteName">Website Name<span style="color: red;">*</span>:</label>
                <input type="text" name="websiteName" formControlName="websiteName"
                    placeholder="Enter new website name." class="form-control">
                <span class="text-danger" *ngIf="(submitted) && f.websiteName.errors?.required">
                    New Website Name is Required
                </span>
            </div>
            <div class="form-group">


                <label for="webSite">Have a website already?<span style="color: red;">*</span>:</label>
                <br>
                <select (change)="showHideTextBox()" formControlName="webSite" name="webSite" id="webSite"
                    class="form-control">
                    <option value="">-- Select --</option>
                    <option value="no">No</option>
                    <option value="yes"> Yes </option>
                </select>
                <span class="text-danger" *ngIf="(submitted) && f.webSite.errors?.required">
                    Website is Required
                </span>
            </div>
            <div class="form-group" *ngIf="showContent">
                <label for="siteUrl">Website<span style="color: red;">*</span>:</label>
                <input type="text" name="siteUrl" formControlName="siteUrl"
                    placeholder="Enter your current website URL." class="form-control">
                <span class="text-danger" *ngIf="(submitted) && f.siteUrl.errors?.required">
                    Site Url Name is Required
                </span>
            </div>
        </div>
        <div class="modal-footer rounded-button">
            <button type="button" class="btn btn-light" (click)="dismissModal()">Cancel</button>
            <button type="button" class="btn  btnPrimary" (click)="startBuildSite()">Submit</button>
        </div>
    </ng-template>

    <div class="d-flex flex-wrap temp-container " style="display: none !important; ">
        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp shortlisted "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/JP.png " alt="Barber Shop "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Barber Shop</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------End Template box--------->

        <!-------Template box 2--------->

        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/AP.png " alt="Vacation "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Vacation</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 3--------->

        <div class="temp-box-style shadow ">
            <div class="new-temp ">New</div>
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/HT.png " alt="Products "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Products</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 4--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/ZE.png " alt="Blank Parallax - RTL "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Blank Parallax - RTL</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 5--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/Bz.png " alt="Blank Parallax - RTL "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Blank Parallax - RTL</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 6--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/dT.png " alt="Hobby Shop "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Hobby Shop</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 7--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp"><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/Wm.png " alt="Recruitment Agency "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Recruitment Agency</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------Template box 8--------->

        <div class="temp-box-style shadow ">
            <div class="tem-box-hover ">
                <a href="# " class="shortlist-temp "><i class="icon-star icons "></i></a>
                <div class="temp-buttons ">
                    <a href="# " class="btn btn-secondary ">Start Building</a>
                    <a href="# " class="btn btn-link ">Preview</a>
                </div>
            </div>
            <div class="temp-image "><img src="/assets/images/temlates/GT.png " alt="Personal Trainer "></div>
            <div class="temp-bottom d-flex ">
                <h4 class="temp-head align-self-center ">Personal Trainer</h4>
                <div class="temp-view align-self-center ml-auto ">
                    <div class="d-flex ">
                        <a href="# " class="icon-screen-desktop icons "></a>
                        <a href="# " class="icon-screen-tablet icons icons "></a>
                        <a href="# " class="icon-screen-smartphone icons icons "></a>
                    </div>
                </div>
            </div>
        </div>

        <!-------End--------->

    </div>


</mat-drawer-container>

<!--Footer-->
<app-footer></app-footer>