import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-my-favorite',
  templateUrl: './my-favorite.component.html',
  styleUrls: ['./my-favorite.component.css']
})
export class MyFavoriteComponent implements OnInit {
  user: any;
  favoriteList: any;
  templatesList: any;
  favList: any;
  favValue: any;
  template: any;
  myFav: Array<any> = [];
  templist = [];
searchText='';
status = false;
  constructor(private apiservice: ApiService, private router: Router) { }
  ngOnInit(): void {
    this.user = this.apiservice.getUser();
    this.getTempltelist();
  }
  getTempltelist() {
    this.apiservice.getUserFavoriteList(this.user.id).subscribe(data => {
      this.favList = data;
    })
    this.apiservice.getTemplateList().subscribe((res: any) => {
      this.templatesList = res.data;

      this.favValue = this.favList;
      this.template = this.templatesList;
      this.favValue.forEach((element: { templateId: number; }, index: number) => {
        let array2 = this.template.filter((d: { template_id: number }) => d.template_id === element.templateId);

        this.myFav.push(array2);

      });
   
    })

  }

  filterfun(index:any,url:any){
    this.templatesList.find((d: {
    id: number
  }) => d.id === index);
  this.templatesList[index].image = url;
}

  startbuildSite(i:any){}

}
