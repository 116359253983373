import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  user: any;
  list: any;
  errMessage = ''
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.user = this.apiService.getUser();
    this.notificationList();
  }
  notificationList() {
    this.apiService.notificationList(this.user.id).subscribe(data => {
  
      if (data.status == 1) {
        this.list = data.data
      }
      else if (data.status == 4) {
        this.errMessage='No Record Found'
      }

    })
  }

}
