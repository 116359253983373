import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../services/api.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser'
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  dangerousVideoUrl: any;
  blogList:any [] = [];
  user: any;
  userLike: any;
  like: boolean = true;
  tag: any;
  videoUrl: SafeResourceUrl | undefined;
  blogImage: SafeResourceUrl | undefined;
  errMessage='';
  constructor(private toast: ToastrService, private sanitizer: DomSanitizer, private router: Router, private apiService: ApiService, private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.tag = localStorage.getItem('tag');
    this.user = this.apiService.getUser();
    if (this.tag === undefined || this.tag === null) {
      this.getBlogList();
    } else {
      this.getBlogByMetaTag();
    }
  }


  getBlogList():any {
    this.spinnerService.show();
    this.apiService.getAllBlogList().subscribe(data => {
      // console.log(data)
      this.spinnerService.hide();
      if (data.status === 4) {
        this.errMessage='No blog found!'
      }
      else if (data.length > 0) {
        this.blogList = data;
      }
    })
  }
  onSelect(data: any):any {
    this.router.navigateByUrl('/blog-detail/' + data);
  }
  likeBlog(id: any) {
    this.getBlogList();
    if (this.user.id >= 0) {
      const blogid = id;
      this.apiService.likeBlog({
        tblUserId: this.user.id,
        tblBlogId: blogid,
      }).subscribe(data => {
      })

    } else {
      this.router.navigateByUrl('/signIn');
    }
  }
  getBlogByMetaTag():any {
    this.apiService.getRecordByMetaTag(this.tag).subscribe(data => {
      this.blogList = data;

    })
  }
}
