

<div class="login-container d-flex">
    <div class="my-auto w-100">
        <div class="row mx-0">
            <div class="col-sm-12 px-0">
                <div class="container-fluid px-0">
                    <div class="login-maxW mx-auto">
                        <div class="row mx-0">
                            <div class="col-md-12 col-lg-5 px-0">
                                <div class="sign-logo">
                                    <a routerLink="/"><img src="/assets/images/logo.png" /></a>
                                </div>
                                <div class="login-content">
                                    <div class="login-center">
                                        <h2 class="text-center">Forgot Password</h2>

                                        <div class="form" [formGroup]="forgetPassword" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <label for="email-for-pass">Email id</label>
                                                <input class="form-control" type="text" id="email-for-pass" placeholder="Enter email id" formControlName="email" required>
                                                <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required ">
                                                    Enter Email id
                                                </span>
                                            </div>
                                            <button class="btn btn-block btn-primary" type="submit" (click)="onSubmit()">Generate New Link</button>
                                        </div>
                                          
                                            <div class="signup-t">Already have an account? <a routerLink="/signIn">Login.</a></div>
                                        </div>
                                    </div>
                                </div>

                            <div class="col-md-12 col-lg-7 px-0 mobilenone text-center">
                                <div class="h-100 d-flex">
                                    <div class="my-auto w-100">
                                        <img src="/assets/images/login-bg.jpg" class="img-fluid" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-----------------------------End------------------ -->


<!-- <section class="user-admin-sections">
    <app-header></app-header>
</section>
<mat-drawer-container class="pt-3 pb-5" autosize style="min-height: 100vh;">

    <mat-drawer #drawer class="user-sidenav" mode="side">
        <app-user-sidenav></app-user-sidenav>
    </mat-drawer>

    

</mat-drawer-container> -->

<!--Footer-->
