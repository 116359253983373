import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.css']
})
export class FailureComponent implements OnInit {
  siteSettings: any;
  constructor(private router: Router, private toaster: ToastrService, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.apiService.getSiteSetting().subscribe(data => {
      this.siteSettings = data.siteData;
    })
    setTimeout(() => this.router.navigateByUrl('/planpricing'), 2000);
  }



}
