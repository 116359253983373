import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { loadStripe, PaymentIntent, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.css'],
})
export class PurchasePlanComponent {
  planDetails:any
  pakageAmount:any=0;
  tokenResponse: any;
  paymentHandler: any = null;
  user: any;
  packageId: any;
  packageList: any;
  settingList: any;
  stripeCustomerId = '';
  stripePriceId ='price_1M1rbmCM7qY2Kwa3gLujeRUd'; //test 'price_1M1rbmCM7qY2Kwa3gLujeRUd'; //prod 'price_1M1TO1CM7qY2Kwa3L7Dj8dWf';
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  stripeTest!: FormGroup;
  subscriptionData:any;
  recurringPayment: boolean = true
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private stripeService: StripeService,
    private spinnerService: NgxSpinnerService,
    private http: HttpClient,
    private fb: FormBuilder,
    ) {}

  ngOnInit() {
    this.getQueryParams()
    this.getSubscribedData()
    this.user = this.apiService.getUser();
    // this.packageId = localStorage.getItem('pkgId');
    // console.log(this.packageId)
    // this.stripeTest = this.fb.group({
    //   name: ['NAME ON THE CARD', [Validators.required]],
    //   amount: [this.pakageAmount, [Validators.required, Validators.pattern(/\d+/)]],
    // });
 
    //  this.initiateSubscription();
    this.getSettings()
  }

  getSettings() {
    this.apiService.getSiteSetting().subscribe((data) => {
      this.settingList = data.siteData;
      console.log(this.settingList)
    });
  }
  async load() {
    const stripePromise = await loadStripe(this.settingList.stripeApiId);
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    // const response = await fetch('/create-checkout-session', { method: 'POST' });

    //const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe?.redirectToCheckout({
      sessionId: this.tokenResponse.id,
    });

    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  makePayment(amount: any) {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.settingList.stripeApiId,
      locale: 'auto',
      token: function (stripeToken: any) {
        // alert('Stripe token generated!');
      },
    });

    paymentHandler.open({
      name: 'Clickimize',
      description: '3 widgets',
      amount: amount * 100,
    });
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.settingList.stripeSecretKey,
          locale: 'auto',
          token: function (stripeToken: any) {
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  async getToken() {
    this.user = this.apiService.getUser();
    this.packageId = localStorage.getItem('pkgId');
    this.apiService.getPackgeById(this.packageId).subscribe((data) => {
      this.packageList = data.package;
      this.apiService
        .getPaymentToken({
          tblUserId: this.user.id,
          tblPackageDetailId: this.packageList.id,
          packageName: this.packageList.packageName,
          packagePrice: this.packageList.packagePrice,
        })
        .subscribe((data) => {
          this.tokenResponse = data;
          this.load();
        });
    });
  }

  async initiateSubscription() {
    this.user = this.apiService.getUser();
    this.packageId = localStorage.getItem('pkgId');
    console.log(this.user)
    this.apiService.getPackgeById(this.packageId).subscribe((data) => {
      console.log(data)
      if (this.user) {
        if (!!this.user.stripe_customer_id) {
          this.stripeCustomerId = this.user.stripe_customer_id;
          this.processSubscription();
        } else {
          this.apiService
            .createCustomer({
              name: this.user.firstName + ' ' + this.user.lastName,
              email: this.user.email,
            })
            .subscribe((res: any) => {
              this.stripeCustomerId = res.id;

              this.processSubscription();
            });
        }
      }
    });
  }

  processSubscription() {
    this.apiService
      .createSubscription({ user:this.user.id,packageId:this.packageId,priceId: this.stripePriceId, customerId: this.stripeCustomerId }).subscribe((res:any)=>{
console.log(res);

this.subscriptionData = res;
      });
}
//   processSubscription() {
//     this.apiService
//       .createSubscription({ user:this.user.id,packageId:this.packageId,priceId: this.stripePriceId, customerId: this.stripeCustomerId }).pipe ( switchMap((pi:any) =>
//       this.stripeService.confirmCardPayment(pi.client_secret, {
//        payment_method: {
//          card: this.card.element,
//          billing_details: {
//            name: this.user.firstName + ' ' + this.user.lastName,
//          },
//        },
//      })
//    )
//  )
//  .subscribe((result:any) => {
//    if (result.error) {
//      // Show error to your customer (e.g., insufficient funds)
//      console.log(result.error.message);
//    } else {
//      // The payment has been processed!
//      if (result.paymentIntent.status === 'succeeded') {
//        // Show a success message to your customer
//      }
//    }
//  });
// }


  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#084c94',
        color: '#000000',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '22px',
        '::placeholder': {
          color: '#000000',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };


onChange(ev: StripeCardElementChangeEvent) {
  const displayError = document.getElementById('card-errors') as any;
  if (ev.error) {
    displayError.textContent = ev.error.message;
  } else {
    displayError.textContent = '';
  }
}

paymentObje: any;
userEmail !: string
// async pay() {

//   this.spinnerService.show();
//     const stripePromise = await loadStripe(environment.stripeKey);
//     const stripe = await stripePromise;
//     if (this.stripeTest.valid) {
//       console.log(this.card)
//       this.stripeService.confirmCardPayment(this.subscriptionData.clientSecret, {
//        payment_method: {
//          card: this.card.element,
//          billing_details: {
//             name: this.user.firstName + ' ' + this.user.lastName,
//          },
//        },
//      })
//  .subscribe((result:any) => {
//   console.log(result)
//    if (result.error) {
//      // Show error to your customer (e.g., insufficient funds)
//      console.log("====> ",result.error.message);
//    } else {
//      // The payment has been processed!
//      if (result.paymentIntent.status === 'succeeded') {
//        // Show a success message to your customer
//        this.userEmail = result.paymentIntent.receipt_email;
//        const date =  new Date();
//       let todayDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
//       let currentPackageId = localStorage.getItem('pkgId');
//        this.paymentObje = {
//         tblUserId: this.user.id,
//         amount: result.paymentIntent.amount,
//         paymentMode: 'online',
//         paymentDate: todayDate,
//         status: 1,
//         transactionRef: result.paymentIntent.client_secret,
//         transactionId: result.paymentIntent.id,
//         packageId : currentPackageId
//        }
//        this.apiService.purchagePlan(this.paymentObje).subscribe(
//         (res: any) => {
//           this.spinnerService.hide();
//           this.router.navigate([`/UserTemplates`]);  
          
//         }
//        )
//      }
//    }
//  });
// } else {
// console.log(this.stripeTest);
// }
// }   
////////////////////////////new code stripe ////////////////

getQueryParams(){
  this.activatedRoute.queryParams.subscribe(params => {
  if(params){
   this.planDetails = params
   console.log(params)
  //  this.pakageAmount = params.pkgAmt
  }
  })
}

pay1(){
  if(!this.recurringPayment){
    this.checkoutAndPay()
  }
  else{
    this.checkoutAndSubscribe()
  }
  //this.checkoutAndPay()
  // this.checkoutAndSubscribe()
  //this.createStripeCustomer()
}

// createStripeCustomer(){
//   this.apiService
//   .createCustomer({
//     name: this.user.firstName + ' ' + this.user.lastName,
//     email: this.user.email,
//   })
//   .subscribe((res: any) => {
//     this.stripeCustomerId = res.id;
//     console.log(res)
//     this.processSubscription();
//     this.pay()
//   });
// }

async checkoutAndPay(){
  const stripePromise = await loadStripe(this.settingList.stripeApiId);
    const stripe = await stripePromise;
  const body = {
    userName :  this.user.email,
    price : this.planDetails.pkgAmt,
    priceId : this.planDetails.amountId, 
    packageId : this.planDetails.pkgId,
    productId :this.planDetails.product,
    packageName : this.planDetails.packagename
  }
 this.apiService.createPaymentSession(body).subscribe(data=>{
  console.log(data)
  if(data?.sessionId){
    const result = stripe?.redirectToCheckout({ 
      sessionId: data.sessionId, 
    });
    result?.catch(err=> console.log(err))
    console.log(result)
  }
 })
}

async checkoutAndSubscribe() {
  const stripePromise = await loadStripe(this.settingList.stripeApiId);
  const stripe = await stripePromise;
  // this.processSubscription()

  const body = {
    userName :  this.user.email,
    price : this.planDetails.pkgAmt,
    priceId : this.planDetails.amountId, 
    packageId : this.planDetails.pkgId,
    productId :this.planDetails.product,
    packageName : this.planDetails.packagename
  }

// const body = {
//   name : "clickimize user: " + this.user.email,
//   price : this.pakageAmount,
//   pkgId :this.packageId 
// }
this.apiService.createSubscribtionPaymentSession(body).subscribe(data=>{
console.log(data)
if(data?.sessionId){
  const result = stripe?.redirectToCheckout({ 
    sessionId: data.sessionId, 
  });
  result?.catch(err=> console.log(err))
  console.log(result)
}
})


  // // Check the server.js tab to see an example implementation
  // this.apiService.createSubscribtionPaymentSession(body)
  //   .pipe(
  //     switchMap(session => {
  //       return this.stripeService.redirectToCheckout({ sessionId: session.id })
  //     })
  //   )
  //   .subscribe(result => {
  //     // If `redirectToCheckout` fails due to a browser or network
  //     // error, you should display the localized error message to your
  //     // customer using `error.message`.
  //     if (result.error) {
  //       alert(result.error.message);
  //     }
  //   });
}
packageDetail : any [] = []
getSubscribedData(){
  this.apiService.purchagePackageDetails.subscribe((planDetails:any)=>{
    // console.log(planDetails)
    this.packageDetail = planDetails
  })
  // console.log(this.packageDetail)
}

}
