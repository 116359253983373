import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { loadStripe } from '@stripe/stripe-js';
import * as moment from 'moment';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  siteSettings: any;
  sessionId: any;
  user: any;
  packageId: any;
  packageList: any;
  checkOutDetails: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toaster: ToastrService, private apiService: ApiService) { }
  ngOnInit(): void {
    this.getQueryParams()
    this.user = JSON.parse(localStorage.getItem("user") || 'null')
    console.log(this.user)
    // this.sessionId = this.route.snapshot.paramMap.get('id');
    // this.packageId = localStorage.getItem('pkgId');
    this.apiService.getSiteSetting().subscribe(data => {
      this.siteSettings = data.siteData;
    })

    // setTimeout(() => this.router.navigateByUrl('/userDashboard'), 2000);
    this.toaster.success('Payment Successfully done');
    // this.getSession();
  }
  // async getSession() {
  //   this.user = this.apiService.getUser();
  //   this.packageId = localStorage.getItem('pkgId');

  //   this.apiService.getPackgeById(this.packageId).subscribe(data => {
  //     this.packageList = data.package;
  //     this.apiService.getSessionData({
  //       tblUserId: this.user.id,
  //       tblPackageDetailId: this.packageList.id,
  //       packageName: this.packageList.packageName,
  //       packagePrice: this.packageList.packagePrice,
  //       session_id: this.sessionId,
  //       userId: this.user.id
  //     }).subscribe(data => {

  //     });
  //   });


  // }


  getQueryParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log(params);
      this.getpaymentDetails(params);


    });
  }
 async getpaymentDetails(params: any) {
    this.apiService.getPaymentDetail(params.session_id).subscribe(async (data: any) => {
      console.log(data)
      this.checkOutDetails = data.subscription
      if(data){
        await this.saveTransactionDetails(params);
      }else{
        setTimeout(() => {
           this.saveTransactionDetails(params);
        }, 500);
      }
  

    })

  }
  gotoDashboard() {
    this.router.navigateByUrl('userDashboard')
  }

  async saveTransactionDetails(params: any) {
    const packageId = (params)
    // console.log(packageId, this.checkOutDetails)
    const date = new Date();
    let todayDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    const paymentObj = {
      tblUserId: this.user.id,
      amount: this.checkOutDetails.amount_subtotal / 100,
      paymentMode: 'online',
      paymentDate: todayDate,
      status: 1,
      transactionRef: this.checkOutDetails.payment_intent || this.checkOutDetails.customer, // session id
      transactionId: this.checkOutDetails.id,
      packageId: params.packageId
    }
    console.log(paymentObj)
    this.apiService.purchagePlan(paymentObj).subscribe(
      (res: any) => {
        console.log(res)
      }
    )
  }

}