<div class="login-container d-flex">
    <div class="my-auto w-100">
        <div class="row mx-0">
            <div class="col-sm-12 px-0">
                <div class="container-fluid px-0">
                    <div class="login-maxW mx-auto">
                        <div class="row mx-0">
                            <div class="col-md-12 col-lg-5 px-0">
                                <div class="sign-logo">
                                    <a routerLink="/"><img src="/assets/images/logo.png" /></a>
                                    <div class="signup-t">Already have an account? <a routerLink="/signIn">Login.</a></div>
                                </div>
                                <div class="login-content">
                                    <div class="login-center">
                                        <h2>Let's Get Started</h2>
                                        <!-- <div class="signupwithGmail">
                                            <a (click)="signUpWithGoogle()" class="btn btn-block"><img src="../../assets/images/google.svg"/> Sign in with Google</a>
                                        </div>
                                        <div class="or-sep"><span>OR</span></div> -->
                                        <form class="form" [formGroup]="manualSignupForm" (ngSubmit)="registerUser()">
    
                                            <div class="row">
                                                
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Enter First Name" formControlName="fName" />
                                                        <span class="text-danger" *ngIf="(f.fName.touched || submitted) && f.fName.errors?.required">
                                                            First Name is required
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lName" />
                                                        <span class="text-danger" *ngIf="(f.lName.touched || submitted) && f.lName.errors?.required">
                                                            Last Name is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Enter Email Address" formControlName="email" />
                                                <span class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                                    Email is required
                                                </span> </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                                                        <span class="text-danger" *ngIf="(f.password.touched || submitted) && f.password.errors?.required">
                                                        Password is required
                                                    </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword" />
                                                        <span class="text-danger" *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors?.required">
                                                       Confirm Password is required
                                                    </span>
                                                        <span class="password-match" *ngIf="f.confirmPassword.errors">Passwords must match</span>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <button class="btn btn-block btn-primary" type="submit">Sign up</button>
                                        </form>
                                        <div class="signup-t">Already have an account? <a routerLink="/signIn">Login.</a></div>
    
                                        
                                        <!-- <div class="signupwithFacebook">
                                            <a (click)="signUpWithFacebook()" class="btn btn-block"><i class="fab fa-facebook-f"></i> Sign up With Facebook</a>
                                        </div> -->
                                    </div>
                                    </div>
                                    



                            </div>

                            <div class="col-md-12 col-lg-7 px-0 mobilenone text-center">
                                <div class="h-100 d-flex">
                                    <div class="my-auto w-100">
                                        <img src="/assets/images/signup.png" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>