import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SocialAuthService } from 

"angularx-social-login";;
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../services/api.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  settingList: any;
  notificationList: any;
  constructor(private AuthService:SocialAuthService,private apiService: ApiService, private router: Router, private cdr: ChangeDetectorRef, private toaster: ToastrService) { }
  user: any;
  isLoggedIn = false;
  ngOnInit(): void {
    this.getSiteToken();
    this.user = this.apiService.getUser();
    if (this.user?.id > 0) {
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
    this.cdr.detectChanges();
  }
  RouterLink(){
    // window.location.href='https://www.clickimize.com/planPricing'
    this.router.navigateByUrl('/planpricing')
  }
  getSiteToken() {
    this.apiService.siteToken().subscribe(data => {
      localStorage.setItem("token", data.accessToken);
      this.apiService.saveToken(data.accessToken);
    })
  }
  ngAfterViewInit() {
    this.apiService.getSiteSetting().subscribe((data: any) => {
      this.settingList = data.siteData;
    })
    this.getUserNofication()
  }

  getUserNofication() {
    this.apiService.getUserNotification(this.user?.id).subscribe(data => {

      if (data.status == 1) {
        this.notificationList = data.data
      }
      else if (data.status == 4) {
        //   this.toaster.error('No Record Found!');
      }
      else if (data.status == 5) {
       // this.toaster.error('Please check internet connection ............!');
      }

    })
  }
  logout() {
    localStorage.removeItem('token');
    this.isLoggedIn = false;
    this.user ={};
    this.apiService.signOut();
    this.router.navigateByUrl('/');
    this.AuthService.signOut().then(data => {   
      this.router.navigate([`/signIn`]);  
    });  

    this.cdr.detectChanges();
  }
  updateNotifiaction(id: any) {
    this.apiService.updateNofication(id, {
      status: 0
    }).subscribe(data => {

      this.getUserNofication();

    })
  }
  onSelect(type: any, id: any) {
    console.log(type)
    this.updateNotifiaction(id)
    this.router.navigateByUrl('/notification')
    // if (type == 'paymnet') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/mypayment');
    // }
    // else if (type == 'Message') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/message');
    // }
    // else if (type == 'subscriber') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/newsletter');
    // }
    // else if (type == 'package') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/myplan');
    // }
    // else if (type == 'blog') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/viewBlog');
    // }
    // else if (type == 'Message') {
    //   this.updateNotifiaction(id);
    //   this.router.navigateByUrl('/chatmessage');
    // }


  }
}
