<!-- <img style="display: block;margin:auto;background-color: mediumblue;" src="{{siteSettings?.siteLogo}}" >

<p style="display: block; margin: auto;text-align: center;" >You will be redirected to the new page, please wait...</p> -->

<div class="container center__div">
    <div class="wrapperAlert">
        <div class="contentAlert">
          <div class="topHalf">
            <p><svg viewBox="0 0 512 512" width="100" title="check-circle">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
              </svg></p>
            <h1>Congratulations</h1>
            <span>{{user.firstName + " " + user.lastName}}</span>
      
           <ul class="bg-bubbles">
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
             <li></li>
           </ul>
          </div>
      
          <div class="bottomHalf">
            <!-- Success! Your payment for 19.99 was successful. -->
            
            <p>Success! Your payment for <strong class="text-success">$ {{checkOutDetails?.amount_subtotal/100}}</strong> was successful.</p>
            <p>Return to your dashboard to manage your site.</p>
            <!-- <p>Bingo! Your payment for <strong class="text-success">$ {{checkOutDetails?.amount_subtotal/100}}</strong> is done successfully.</p> -->
            <!-- <p>Your payment session id is <strong>{{checkOutDetails?.id}}</strong></p>  -->
            <!-- <p> Enjoy publishing your websites and get other plan benifits.</p> -->
            <h6>Thank you</h6>
            <button (click)="gotoDashboard()" id="alertMO" class="btn btn-primary mt-4">Go to Dashboard</button>
          </div>
      
        </div>        
      
      </div>
</div>
